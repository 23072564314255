import { GetLaborerCVRequestResponse } from 'api/contractAuthAPI'
import { CallAPIAction, Status } from 'api/types'
import { ListingCVRequest } from 'common/types/commonTypes'
import { getEmptyListingCVRequest } from 'common/utils/listingRequestHelper'
import Actions from 'redux/actions'

type State = {
  data: ListingCVRequest
} & Status

const initialState: State = {
  data: getEmptyListingCVRequest(),
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_LISTING_LABORER_CV_REQUEST_ASYNC,
    Actions.GET_LISTING_LABORER_CV_REQUEST_SUCCESS,
    Actions.GET_LISTING_LABORER_CV_REQUEST_FAIL,
    GetLaborerCVRequestResponse
  >,
) => {
  switch (action.type) {
    case Actions.GET_LISTING_LABORER_CV_REQUEST_ASYNC:
    case Actions.GET_LISTING_LABORER_CV_REQUEST_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    case Actions.GET_LISTING_LABORER_CV_REQUEST_SUCCESS: {
      const { data } = action.payload

      return {
        ...state,
        ...action.status,
        data: {
          id: action.payload.data.id,
          birthDate: data.birth_date,
          city: {
            id: 0,
            label: data.city.label_ar,
            labelEn: data.city.label_en,
          },
          createdAt: data.created_at,
          employer: data.employer_name,
          employerMobile: data.employer_mobile,
          experienceInSaudi: data.experience_in_saudi,
          gender: data.gender,
          iqamaNumber: data.iqama_number,
          laborerMobile: data.laborer_mobile ?? '-',
          martialStatus: data.martial_status,
          name: {
            id: 0,
            label: data.name,
            labelEn: data.name_en,
          },
          qualification: data.qualification ?? '-',
          religion: data.religion,
          salary: data.salary,
          status: data.status,
          profileImage: data.laborer_profile,
          occupation: {
            id: 0,
            label: data.occupation.label,
            labelEn: data.occupation.label_en,
          },
          nationality: {
            id: 0,
            label: data.nationality.label,
            labelEn: data.nationality.label_en,
          },
          serviceCost: data.transfer_service_cost,
          skills: data.skills.map((skill) => ({
            id: skill.id,
            label: skill.label_ar,
            labelEn: skill.label_en,
          })),
          expireAt: data.expire_at || '',
        },
      }
    }
    default:
      return state
  }
}
