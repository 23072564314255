import { CheckListLaborerEligibilityResponse } from 'api/contractAuthAPI'
import { Status, CallAPIAction } from 'api/types'
import Actions from 'redux/actions'

type State = {
  occupationId: string
  message?: string
} & Status

const initialState = {
  message: '',
  occupationId: '',
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.CHECK_LIST_LABORER_ELIGIBILITY_ASYNC,
    Actions.CHECK_LIST_LABORER_ELIGIBILITY_SUCCESS,
    Actions.CHECK_LIST_LABORER_ELIGIBILITY_FAIL,
    {
      occupationEvisaData?: {
        id: string
      }
    },
    CheckListLaborerEligibilityResponse
  >,
): State => {
  switch (action.type) {
    case Actions.CHECK_LIST_LABORER_ELIGIBILITY_ASYNC: {
      return {
        ...state,
        ...action.status,
      }
    }

    case Actions.CHECK_LIST_LABORER_ELIGIBILITY_SUCCESS: {
      return {
        ...state,
        ...action.status,
        occupationId: action.payload.occupationEvisaData?.id || '',
      }
    }
    case Actions.CHECK_LIST_LABORER_ELIGIBILITY_FAIL: {
      return {
        ...state,
        ...action.status,
        message: action.payload?.message || action.payload?.error?.message,
      }
    }
    default:
      return state
  }
}
