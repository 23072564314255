import { CallAPIAction, Status } from 'api/types'
import Actions from 'redux/actions'

type State = {} & Status

const initialState: State = {
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.ACCEPT_LISTING_OFFER_ASYNC,
    Actions.ACCEPT_LISTING_OFFER_SUCCESS,
    Actions.ACCEPT_LISTING_OFFER_FAIL,
    {}
  >,
): State => {
  switch (action.type) {
    case Actions.ACCEPT_LISTING_OFFER_ASYNC:
    case Actions.ACCEPT_LISTING_OFFER_SUCCESS:
    case Actions.ACCEPT_LISTING_OFFER_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }
    default:
      return state
  }
}
