import { AxiosRequestConfig } from 'axios'

import { LIST_ITEMS_PER_PAGE } from 'common/constants'
import {
  AuthContractLaborerStatus,
  PlaceOfResidencyType,
  RelativeRelationType,
  ResidencyDescriptionType,
  ContractAuthRequestStatus,
  GeneralFailResponse,
  PaginatorBE,
  ContractAuthAppendixStatus,
  ListingCVRequestFromBE,
  ListingRequestStatus,
} from 'common/types/commonTypes'
import {
  AlternativeObjectWithTranslationFromBE,
  NationalityFromBE,
  ObjectWithTranslationFromBE,
  ObjectWithTranslationFromBEWithoutId,
  OccupationFromBE,
} from 'common/types/transformedRespTypes'
import { UncontractableReasons } from 'common/utils/convertResponse/convertAuthApiResponse'

import { callAPI, contractAuthAPI, contractAuthAPIWithCredentials } from './axios'

export type GetEmployerLaborersParams = {
  birthDate: string
  wps?: boolean
}

export type GetEmployerLaborersResponse = Array<{
  id: string
  OccupationName?: string
  nationalityName?: string
  authenticationStatus: AuthContractLaborerStatus
  occupation?: OccupationFromBE
  nationality?: NationalityFromBE & {
    is_contract_authentication_allowed: string
  }
  payrollAmt: number
  borderNo: string
  name: string
  personalStatus: string
  idExpiryDate: string
  religion: string
  birthDate: string
  entryDate: string
  finalExitVisaIssued: string
  requestId?: number
  requestIdSource?: 'CONTRACTS_AUTHENTICATION' | 'ETAWTHEEQ'
  contractNo: string
  uncontractableReason: UncontractableReasons
}>

export type GetEmployerLaborersFailResponse = GeneralFailResponse

const getEmployerLaborers = ({ birthDate }: GetEmployerLaborersParams, config?: AxiosRequestConfig) =>
  contractAuthAPIWithCredentials.get<GetEmployerLaborersResponse>(`/api/musaned/employers/${birthDate}/labors`, config)

export type CheckListLaborerEligibilityParams = {
  id: number
  date: string
}

export type CheckListLaborerEligibilityResponse = {
  message?: string
  error?: {
    message?: string
  }
}

const checkListLaborerEligibility = ({ id, date }: CheckListLaborerEligibilityParams, config?: AxiosRequestConfig) =>
  contractAuthAPIWithCredentials.post<CheckListLaborerEligibilityResponse>(
    'api/musaned/employers/laborers/check_eligibility',
    {
      id_number: id,
      date,
    },
    config,
  )

export type GetContractAuthRequestsListParams = {
  status?: string
  page?: number
  perPage?: number
}

export type MobileNumberChangeNoticeFromBE = {
  id: number
  changed_by: string
  created_at: string
  deleted_at: string | null
  labor_id: number
  new_mobile_number: string
  old_mobile_number: string
  status: ContractAuthAppendixStatus
  updated_at: string
}

export type LaborDetailsFromBE = {
  nationality: NationalityFromBE
  occupation: OccupationFromBE
  religion: string
  passport_expiry_date: string
  passport_number: string
  relative_name: string
  relative_mobile_number: string
  relative_relation: RelativeRelationType | string
  martial_status: string
  iqama_expiry_date: string
  mobile_number: string
}

export type ResidencyDetailsFromBE = {
  place: PlaceOfResidencyType
  address: string
  description: ResidencyDescriptionType | string
  residents_number: number | null
  additional_number: string | null
  building_number: string | null
  city: string | null
  district: string | null
  postal_code: string | null
  region: string | null
  street_name: string | null
}

export type AppendixFromBE = {
  id: number
  status: ContractAuthAppendixStatus
  updated_at: string
  approved_at: string
  authenticated_contract_id: number
  created_at: string
  deleted_at: string | null
  duration_in_months: number | null
  salary: string
  start_date_details: {
    day: string
    day_en: string
    gregorian: string
    hijri: string
  } | null
  uuid: string
  labor_details: LaborDetailsFromBE | null
  residency_details: ResidencyDetailsFromBE | null
}

export type PendingAppendixFromBE = {
  id: number
  status: ContractAuthAppendixStatus
  updated_at: string
  approved_at: string
  authenticated_contract_id: number
  created_at: string
  deleted_at: string | null
  duration_in_months: number | null
  salary: string
  start_date_details: {
    day: string
    day_en: string
    gregorian: string
    hijri: string
  } | null
  uuid: string
}

export type ContractAuthRequestFromBE = {
  id: number
  status: ContractAuthRequestStatus
  iqama_number: string
  name: string
  name_en: string
  nationality: ObjectWithTranslationFromBEWithoutId
  occupation: ObjectWithTranslationFromBEWithoutId
  created_at: string
  start_date: string | null
  rejectionReasons: Array<ObjectWithTranslationFromBE>
  last_appendix_at: string | null
  appendixes: Array<AppendixFromBE> | null
  last_appendix_status: ContractAuthAppendixStatus | null
  mobile_number_change_notice: MobileNumberChangeNoticeFromBE | null
  uuid?: string
  pending_appendix?: PendingAppendixFromBE
}

export type GetContractAuthRequestsListResponse = PaginatorBE<ContractAuthRequestFromBE>

const getContractAuthRequestsList = (
  { page = 0, perPage = LIST_ITEMS_PER_PAGE, status }: GetContractAuthRequestsListParams,
  config?: AxiosRequestConfig,
) =>
  contractAuthAPIWithCredentials.get<GetContractAuthRequestsListResponse>('/api/musaned/employers/contracts', {
    params: {
      page: page + 1,
      per_page: perPage,
      status,
    },
    ...config,
  })

export type GetContractAuthDetailedRequestResponse = {
  authenticated_contract: {
    duration_in_months: number
    new_salary: string
    status: ContractAuthRequestStatus
    employer_details: {
      name: string
      nationality: string
      id_number: string
      children_under_twelve_count: string
      address: string
      mobile_number: string
      extra_mobile_number: string
      email: string
      job: string
      family_size: string
    }
    labor: {
      name: string
      birth_date: string
      name_en: string
      iqama_number: string
      mobile_number: string
    }
    labor_details: LaborDetailsFromBE
    residency_details: ResidencyDetailsFromBE
    occupation_descriptions: Array<AlternativeObjectWithTranslationFromBE>
    start_date_details: {
      day: string
      day_en: string
      gregorian: string
      hijri: string
    } | null
    approved_appendixes?: Array<AppendixFromBE>
    uuid?: string
    created_at: string
    pending_appendix?: PendingAppendixFromBE
    legal_text: {
      ar_file_id: string
      en_file_id: string
    } | null
  }
}

export type GetContractAuthDetailedRequestParams = {
  requestId: number | string
}

const getContractAuthDetailedRequest = (
  { requestId }: GetContractAuthDetailedRequestParams,
  config?: AxiosRequestConfig,
) =>
  contractAuthAPIWithCredentials.get<GetContractAuthDetailedRequestResponse>(
    `/api/musaned/employers/contracts/${requestId}`,
    config,
  )

export type ContractAuthResidency = {
  place: PlaceOfResidencyType
  description: ResidencyDescriptionType | string
  region: string | null
  city: string | null
  district: string | null
  street_name: string | null
  building_number: string | null
  additional_number: string | null
  postal_code: string | null
}

export type ContractAuthRequestAppendix = {
  salary: string
  duration_in_months: number
  passport_number: string
  passport_expiry_date: string
  residency: Partial<ContractAuthResidency>
}

export type CreateContractAuthRequestAppendixParams = {
  contractId: number | string
  body: Partial<ContractAuthRequestAppendix>
}

const createContractAuthRequestAppendix = (
  { contractId, body }: CreateContractAuthRequestAppendixParams,
  config?: AxiosRequestConfig,
) =>
  contractAuthAPIWithCredentials.post<CreateContractAuthRequestResponse>(
    `/api/musaned/employers/contracts/${contractId}/appendix`,
    body,
    config,
  )

export type CreateContractAuthRequestParams = {
  iqamaNumber: string
  employerDate: string
  contract: {
    salary: string
    durationInYears?: string
    durationInMonths?: string
    jobDescriptions: Array<number>
  }
  residency: {
    place: string
    address?: string
    region?: string
    city?: string
    district?: string
    street?: string
    buildingNumber?: string
    additionalNumber?: string
    postalCode?: string
    description: string
    residentsNumber?: number
  }
  contact: {
    passport: string
    passportExpiryDate: string
    laborMobileNumber: string
    relativeName: string
    relativeMobileNumber: string
    relativeRelation: string
  }
}
export type CreateContractAuthRequestResponse = {}

export type CreateContractAuthRequestFailResponse = GeneralFailResponse

const createContractAuthRequest = (params: CreateContractAuthRequestParams, config?: AxiosRequestConfig) =>
  contractAuthAPIWithCredentials.post<CreateContractAuthRequestResponse>(
    '/api/musaned/employers/contracts',
    {
      iqama_number: params.iqamaNumber,
      employer_date: params.employerDate,
      contract: {
        salary: params.contract.salary,
        duration_in_months: params.contract.durationInMonths,
        job_descriptions: params.contract.jobDescriptions,
      },
      residency: {
        place: params.residency.place,
        address: params.residency.address,
        region: params.residency.region,
        city: params.residency.city,
        district: params.residency.district,
        street_name: params.residency.street,
        building_number: params.residency.buildingNumber,
        additional_number: params.residency.additionalNumber,
        postal_code: params.residency.postalCode,
        description: params.residency.description,
        ...(typeof params.residency.residentsNumber === 'number'
          ? { residents_number: params.residency.residentsNumber }
          : {}),
      },
      contact: {
        passport: params.contact.passport,
        passport_expiry_date: params.contact.passportExpiryDate,
        labor_mobile_number: params.contact.laborMobileNumber,
        relative_name: params.contact.relativeName,
        relative_mobile_number: params.contact.relativeMobileNumber,
        relative_relation: params.contact.relativeRelation,
      },
    },
    config,
  )

export type GetAuthContractDraftResponse = {
  legal_text_ar: string
  legal_text_en: string
}

export type GetAuthContractDraftFailResponse = GeneralFailResponse

export type GetAuthContractDraftParams = {
  iqamaNumber: string
  contract: {
    salary?: string
    durationInMonths: number
    jobDescriptions: string
  }
  residency: {
    place: string
    region?: string
    city?: string
    district?: string
    street?: string
    buildingNumber?: string
    additionalNumber?: string
    postalCode?: string
    description: string
  }
  contact: {
    passport: string
    passportExpiryDate: string
    laborMobileNumber: string
    relativeName: string
    relativeMobileNumber: string
    relativeRelation: string
  }
}
const getAuthContractDraft = (params: GetAuthContractDraftParams, config?: AxiosRequestConfig) =>
  contractAuthAPIWithCredentials.post<GetAuthContractDraftResponse>(
    '/api/musaned/employer/contract/draft',
    {
      iqama_number: params.iqamaNumber,
      contract: {
        salary: params.contract.salary,
        duration_in_months: params.contract.durationInMonths,
        job_descriptions: params.contract.jobDescriptions,
      },
      residency: {
        place: params.residency.place,
        region: params.residency.region,
        city: params.residency.city,
        district: params.residency.district,
        street_name: params.residency.street,
        building_number: params.residency.buildingNumber,
        additional_number: params.residency.additionalNumber,
        postal_code: params.residency.postalCode,
        description: params.residency.description,
      },
      contact: {
        passport: params.contact.passport,
        passport_expiry_date: params.contact.passportExpiryDate,
        labor_mobile_number: params.contact.laborMobileNumber,
        relative_name: params.contact.relativeName,
        relative_mobile_number: params.contact.relativeMobileNumber,
        relative_relation: params.contact.relativeRelation,
      },
    },
    config,
  )

export type CancelContractAuthRequestParams = {
  requestId: number | string
  employerDate: string
}

export type CancelContractAuthRequestResponse = {}
export type CancelContractAuthRequestFailResponse = GeneralFailResponse

const cancelContractAuthRequest = (
  { requestId, employerDate }: CancelContractAuthRequestParams,
  config?: AxiosRequestConfig,
) =>
  contractAuthAPIWithCredentials.post<GetContractAuthDetailedRequestResponse>(
    `/api/musaned/employers/contracts/${requestId}/cancel`,
    {
      employer_date: employerDate,
    },
    config,
  )

export type CheckLaborerPhoneForContractAuthParams = {
  iqamaNumber: string
  mobileNumber: string
}

export type CheckLaborerPhoneForContractAuthResponse = {
  mobile_number: string
  used: boolean
  excluded: string
}

const checkLaborerPhoneForContractAuth = (
  { iqamaNumber, mobileNumber }: CheckLaborerPhoneForContractAuthParams,
  config?: AxiosRequestConfig,
) =>
  contractAuthAPIWithCredentials.get<CheckLaborerPhoneForContractAuthResponse>(
    `/api/musaned/labors/${iqamaNumber}/mobile/${mobileNumber}`,
    config,
  )

export type UpdateLaborerMobileNumberParams = {
  iqamaNumber: string
  mobileNumber: string
}

export type UpdateLaborerMobileNumberResponse = {}

export type UpdateLaborerMobileNumberFailResponse = GeneralFailResponse

const updateLaborerMobileNumber = (
  { iqamaNumber, mobileNumber }: UpdateLaborerMobileNumberParams,
  config?: AxiosRequestConfig,
) =>
  contractAuthAPIWithCredentials.post<UpdateLaborerMobileNumberResponse>(
    `/api/musaned/labors/${iqamaNumber}/mobile/${mobileNumber}`,
    config,
  )

export type CancelAppendixRequestParams = {
  requestUuid: number | string
  appendixUuid: number | string
}

const cancelContractAuthAppendix = (
  { requestUuid, appendixUuid }: CancelAppendixRequestParams,
  config: AxiosRequestConfig,
) =>
  contractAuthAPIWithCredentials.post<{}>(
    `/api/musaned/employers/contracts/${requestUuid}/appendix/${appendixUuid}/cancel`,
    {},
    config,
  )

export type GetSimpleAuthContractInfoParams = {
  contractId: string
}

export type GetSimpleAuthContractInfoResponse = {
  data: {
    id: number
    status: ContractAuthRequestStatus
    duration_in_months: number
    laborer_details: {
      name: string
      mobile_number: string
      nationality: {
        label: string
        label_en: string
      }
    }
    employer_details: {
      name: string
      email: string
      mobile_number: string
    }
    start_date_details: {
      hijri: string
      gregorian: string
    }
  }
}

const getSimpleAuthContractInfo = ({ contractId }: GetSimpleAuthContractInfoParams, config: AxiosRequestConfig) =>
  contractAuthAPI.get<GetSimpleAuthContractInfoResponse>(`/api/contracts/${contractId}/simple`, config)

export type GetAuthContractPdfParams = {
  requestId: string
  fileId: string
}

export type GetAuthContractPdfResponse = {
  url: string
}

const getAuthContractPdf = ({ requestId, fileId }: GetAuthContractPdfParams, config?: AxiosRequestConfig) =>
  contractAuthAPIWithCredentials.get<GetAuthContractPdfResponse>(
    `api/musaned/employer/contract/${requestId}/pdf/${fileId}`,
    config,
  )

export type GetAuthContractParams = {
  requestId: string
}

export type GetAuthContractResponse = {
  data: {
    label_ar: string
    label_en: string
  }
}

const getAuthContract = ({ requestId }: GetAuthContractParams, config?: AxiosRequestConfig) =>
  contractAuthAPIWithCredentials.get<GetAuthContractResponse>(
    `api/musaned/employer/contract/${requestId}/legal_text`,
    config,
  )

export type GetPendingAppendixParams = {
  requestId: string
  appendixId: number
}

export type GetPendingAppendixResponse = {
  legalTextAr: string
  legalTextEn: string
}

const getPendingAppendix = ({ requestId, appendixId }: GetPendingAppendixParams, config?: AxiosRequestConfig) =>
  contractAuthAPIWithCredentials.get<GetPendingAppendixResponse>(
    `api/musaned/employer/contract/${requestId}/appendixes/${appendixId}/draft`,
    config,
  )

export type SendLaborerReportParams = {
  borderNumber: number
  mobileNumber: number
}

export type SendLaborerReportResponse = {}

export type SendLaborerReportFailResponse = {
  message?: string
}

const sendLaborerReport = ({ borderNumber, mobileNumber }: SendLaborerReportParams, config?: AxiosRequestConfig) =>
  contractAuthAPIWithCredentials.post<SendLaborerReportResponse>(
    'api/musaned/employers/runaway/submit',
    {
      query_number: borderNumber,
      mobile_number: mobileNumber,
    },
    config,
  )

export type CancelLaborerReportParams = {
  borderNumber: number
}

export type CancelLaborerReportResponse = {}

export type CancelLaborerReportFailResponse = {
  message?: string
}

const cancelLaborerReport = ({ borderNumber }: CancelLaborerReportParams, config?: AxiosRequestConfig) =>
  contractAuthAPIWithCredentials.post<SendLaborerReportResponse>(
    'api/musaned/employers/runaway/cancel',
    {
      query_number: borderNumber,
    },
    config,
  )

export type CheckLaborerHasReportParams = {
  laborerId: number
}

export type CheckLaborerHasReportResponse = {
  has_run_away_reports: boolean
}

const checkLaborerHasReport = ({ laborerId }: CheckLaborerHasReportParams, config?: AxiosRequestConfig) =>
  contractAuthAPIWithCredentials.get<CheckLaborerHasReportResponse>(
    `api/musaned/employers/runaway/${laborerId}/has-active`,
    config,
  )

export type ListLaborerCVParams = {
  iqamaNumber: number
  mobileNumber: string
  serviceCost: string
  monthlySalary: string
  yearsOfExperience?: string
  laborerCityId: number
  qualification?: string
  sponsorInfoEnabled: boolean
  profilePicture?: File
  skills?: Array<string>
}

export type ListLaborerCVResponse = {}
export type ListLaborerCVFailResponse = {
  message?: string
}

const listLaborerCVBody = (body: ListLaborerCVParams) => {
  const data = new FormData()
  data.append('iqama_number', body.iqamaNumber.toString())
  data.append('mobile_number', body.mobileNumber)
  data.append('transfer_service_cost', body.serviceCost)
  data.append('monthly_salary', body.monthlySalary)
  data.append('laborer_city_id', body.laborerCityId.toString())
  data.append('sponsor_info_enabled', body.sponsorInfoEnabled ? '1' : '0')

  if (body.yearsOfExperience) data.append('years_of_experience_ksa', body.yearsOfExperience)
  if (body.qualification) data.append('educational_qualification', body.qualification)
  if (body.profilePicture) data.append('profile_picture', body.profilePicture)
  if (body.skills?.length) body.skills.forEach((skill) => data.append('skills[]', skill))

  return data
}

const listLaborerCV = (params: ListLaborerCVParams, config?: AxiosRequestConfig) =>
  contractAuthAPIWithCredentials.post<ListLaborerCVResponse>(
    'api/musaned/employers/marketplace/submit',
    listLaborerCVBody(params),
    config,
  )

export type CancelListingLaborerCVParams = {
  requestId: number
}

export type CancelListingLaborerCVResponse = {}

const cancelListingLaborerCV = ({ requestId }: CancelListingLaborerCVParams, config?: AxiosRequestConfig) =>
  contractAuthAPIWithCredentials.post<CancelListingLaborerCVResponse>(
    `api/musaned/employers/marketplace/${requestId}/cancel`,
    {},
    config,
  )

export type GetLaborerCVRequestParams = {
  laborerId: string
}

export type GetLaborerCVRequestResponse = {
  data: ListingCVRequestFromBE
}

const getListingLaborerCVRequest = ({ laborerId }: GetLaborerCVRequestParams, config?: AxiosRequestConfig) =>
  contractAuthAPIWithCredentials.get<GetLaborerCVRequestResponse>(
    `api/musaned/employers/marketplace/${laborerId}/has-request`,
    config,
  )

export type GetListingCVsListParams = {
  gender: string
  occupation: string
  nationality: string
  arrivalCityId?: number
  religion?: string
  age?: number
  experience?: number
  perPage?: number
  page: number
}

export type GetListingCVsListResponse = {
  data: Array<ListingCVRequestFromBE>
  meta: {
    last_page: number
  }
}

const getListingCVsList = (params: GetListingCVsListParams, config?: AxiosRequestConfig) =>
  contractAuthAPIWithCredentials.get<GetListingCVsListResponse>('/api/musaned/employers/marketplace', {
    params: {
      page: params.page || 0,
      per_page: params.perPage || 5,
      gender: params.gender.toUpperCase(),
      occupation: params.occupation,
      nationality: params.nationality,
      city_id: params.arrivalCityId,
      religion: params.religion,
      age: params.age,
      years_of_experience: params.experience,
    },
    ...config,
  })

export type SelectListingCVParams = {
  id: string
  duration: number
}

export type SelectListingCVResponse = {}

export type SelectListingCVFailResponse = {
  message: string
}

const selectListingCV = ({ id, duration }: SelectListingCVParams, config?: AxiosRequestConfig) =>
  contractAuthAPIWithCredentials.post<SelectListingCVResponse>(
    `api/musaned/employers/marketplace/${id}/select`,
    {
      contract_duration: duration,
    },
    config,
  )

export type ListingRequestFromBE = {
  id: number
  name: string
  name_en: string
  iqama_number: string
  occupation: {
    label: string
    label_en: string
  }
  nationality: {
    label: string
    label_en: string
  }
  is_owner: boolean
  status: ListingRequestStatus
  expire_at: string
  created_at: string
  sent_offers: Array<{
    id: string
    status: string
    updated_at: string
  }>
  received_offers: Array<{
    id: string
    status: string
    updated_at: string
  }>
}

export type GetListingRequestListParams = {
  requestStatus?: Array<string>
  offerStatus?: Array<string>
  requestType?: string
  page?: number
  perPage?: number
}

export type GetListingRequestListResponse = {
  data: Array<ListingRequestFromBE>
  meta: {
    last_page: number
    current_page: number
  }
}

const getListingRequestList = (params: GetListingRequestListParams, config?: AxiosRequestConfig) =>
  contractAuthAPIWithCredentials.get<GetListingRequestListResponse>('/api/musaned/employers/marketplace/list', {
    params: {
      request_status: params.requestStatus?.[0] !== 'ALL' && params.requestStatus ? params.requestStatus : undefined,
      offer_status: params.offerStatus,
      request_type: params.requestType !== 'ALL' && params.requestType ? params.requestType : undefined,
      page: (params.page || 0) + 1,
      per_page: params.perPage || LIST_ITEMS_PER_PAGE,
    },
    ...config,
  })

export type GetListingRequestParams = {
  id: string
}

export type GetListingRequestResponse = {
  data: ListingCVRequestFromBE
}

const getListingRequest = ({ id }: GetListingRequestParams, config?: AxiosRequestConfig) =>
  contractAuthAPIWithCredentials.get<GetListingRequestResponse>(`api/musaned/employers/marketplace/${id}`, config)

export type ExtendListingRequestParams = {
  id: number
}

export type ExtendListingRequestResponse = {}

const extendListingRequest = ({ id }: ExtendListingRequestParams, config?: AxiosRequestConfig) =>
  contractAuthAPIWithCredentials.post<{}>(`api/musaned/employers/marketplace/${id}/extend`, config)

export type ResubmitListingRequestParams = {
  id: number
}

const resubmitListingRequest = ({ id }: ExtendListingRequestParams, config?: AxiosRequestConfig) =>
  contractAuthAPIWithCredentials.post<{}>(`api/musaned/employers/marketplace/${id}/resubmit`, config)

export type DisableListingRequestParams = {
  id: number
}

const disableListingRequest = ({ id }: DisableListingRequestParams, config?: AxiosRequestConfig) =>
  contractAuthAPIWithCredentials.post<{}>(`api/musaned/employers/marketplace/${id}/disable`, config)

export type GetListingOfferParams = {
  id: string
}

export type GetListingOfferResponse = {
  data: {
    laborer_details: {
      age: string
      educational_qualification: string
      iqama_number: string
      location: null
      name: string
      nationality: string
      occupation: string
      professional_skills: Array<{
        id: number
        label_ar: string
        label_en: string
      }>
      religion: string
      work_experience: number
      laborer_profile?: string
    }
    new_employer_details: {
      city: string
      mobile: string
      name: string
    }
    request_details: {
      expire_at: string
      monthly_salary: number
      request_date: string
      request_status: string
      transfer_service_cost: number
    }
  }
}

const getListingOffer = ({ id }: GetListingOfferParams, config?: AxiosRequestConfig) =>
  contractAuthAPIWithCredentials.get<GetListingOfferResponse>(`api/musaned/employers/marketplace/offer/${id}`, config)

export type AcceptListingOfferParams = {
  id: string
}

const acceptListingOffer = ({ id }: AcceptListingOfferParams, config?: AxiosRequestConfig) =>
  contractAuthAPIWithCredentials.post<{}>(`api/musaned/employers/marketplace/offer/${id}/accept`, config)

export type RejectListingOfferParams = {
  id: string
}

const rejectListingOffer = ({ id }: RejectListingOfferParams, config?: AxiosRequestConfig) =>
  contractAuthAPIWithCredentials.post<{}>(`api/musaned/employers/marketplace/offer/${id}/reject`, config)

export default {
  getEmployerLaborers: callAPI<typeof getEmployerLaborers, GetEmployerLaborersResponse>(getEmployerLaborers),
  getContractAuthRequestsList: callAPI<typeof getContractAuthRequestsList, GetContractAuthRequestsListResponse>(
    getContractAuthRequestsList,
  ),
  getContractAuthDetailedRequests: callAPI<
    typeof getContractAuthDetailedRequest,
    GetContractAuthDetailedRequestResponse
  >(getContractAuthDetailedRequest),
  createContractAuthRequest: callAPI<
    typeof createContractAuthRequest,
    CreateContractAuthRequestResponse,
    CreateContractAuthRequestFailResponse
  >(createContractAuthRequest),
  getAuthContractDraft: callAPI<
    typeof getAuthContractDraft,
    GetAuthContractDraftResponse,
    GetAuthContractDraftFailResponse
  >(getAuthContractDraft),
  cancelContractAuthRequest: callAPI<
    typeof cancelContractAuthRequest,
    CancelContractAuthRequestResponse,
    CancelContractAuthRequestFailResponse
  >(cancelContractAuthRequest),
  checkLaborerPhoneForContractAuth: callAPI<
    typeof checkLaborerPhoneForContractAuth,
    CheckLaborerPhoneForContractAuthResponse
  >(checkLaborerPhoneForContractAuth),
  createContractAuthRequestAppendix: callAPI<
    typeof createContractAuthRequestAppendix,
    CreateContractAuthRequestResponse
  >(createContractAuthRequestAppendix),
  updateLaborerMobileNumber: callAPI<typeof updateLaborerMobileNumber, UpdateLaborerMobileNumberResponse>(
    updateLaborerMobileNumber,
  ),
  cancelContractAuthAppendix: callAPI<typeof cancelContractAuthAppendix, {}>(cancelContractAuthAppendix),
  getSimpleAuthContractInfo: callAPI<typeof getSimpleAuthContractInfo, GetSimpleAuthContractInfoResponse>(
    getSimpleAuthContractInfo,
  ),
  getAuthContractPdf: callAPI<typeof getAuthContractPdf, GetAuthContractPdfResponse, GeneralFailResponse>(
    getAuthContractPdf,
  ),
  getAuthContract: callAPI<typeof getAuthContract, GetAuthContractResponse, GeneralFailResponse>(getAuthContract),
  getPendingAppendix: callAPI<typeof getPendingAppendix, GetPendingAppendixResponse, GeneralFailResponse>(
    getPendingAppendix,
  ),
  sendLaborerReport: callAPI<typeof sendLaborerReport, SendLaborerReportResponse>(sendLaborerReport),
  cancelLaborerReport: callAPI<typeof cancelLaborerReport, CancelLaborerReportResponse>(cancelLaborerReport),
  checkLaborerHasReport: callAPI<typeof checkLaborerHasReport, CheckLaborerHasReportResponse>(checkLaborerHasReport),
  checkListLaborerEligibility: callAPI<typeof checkListLaborerEligibility, CheckListLaborerEligibilityResponse>(
    checkListLaborerEligibility,
  ),
  listLaborerCV: callAPI<typeof listLaborerCV, ListLaborerCVResponse>(listLaborerCV),
  cancelListingLaborerCV: callAPI<typeof cancelListingLaborerCV, CancelListingLaborerCVResponse>(
    cancelListingLaborerCV,
  ),
  getListingLaborerCVRequest: callAPI<typeof getListingLaborerCVRequest, GetLaborerCVRequestResponse>(
    getListingLaborerCVRequest,
  ),
  getListingCVsList: callAPI<typeof getListingCVsList, GetListingCVsListResponse>(getListingCVsList),
  selectListingCV: callAPI<typeof selectListingCV, SelectListingCVResponse>(selectListingCV),
  getListingRequestList: callAPI<typeof getListingRequestList, GetListingRequestListResponse>(getListingRequestList),
  getListingRequest: callAPI<typeof getListingRequest, GetListingRequestResponse>(getListingRequest),
  extendListingRequest: callAPI<typeof extendListingRequest, {}>(extendListingRequest),
  resubmitListingRequest: callAPI<typeof resubmitListingRequest, {}>(resubmitListingRequest),
  disableListingRequest: callAPI<typeof disableListingRequest, {}>(disableListingRequest),
  getListingOffer: callAPI<typeof getListingOffer, GetListingOfferResponse>(getListingOffer),
  acceptListingOffer: callAPI<typeof acceptListingOffer, {}>(acceptListingOffer),
  rejectListingOffer: callAPI<typeof rejectListingOffer, {}>(rejectListingOffer),
}
