import { ListingCVRequest, ListingCVRequestFromBE, ListingRequestStatus } from 'common/types/commonTypes'

export const getEmptyListingCVRequest = () => ({
  id: 0,
  birthDate: '',
  city: {
    id: 0,
    label: '',
    labelEn: '',
  },
  createdAt: '',
  employer: '',
  employerMobile: '',
  experienceInSaudi: 0,
  gender: '',
  iqamaNumber: '',
  laborerMobile: '',
  martialStatus: '',
  name: {
    id: 0,
    label: '',
    labelEn: '',
  },
  qualification: '',
  religion: '',
  salary: 0,
  status: ListingRequestStatus.WAITING_LABORER_APPROVAL,
  profileImage: '',
  occupation: {
    id: 0,
    label: '',
    labelEn: '',
  },
  nationality: {
    id: 0,
    label: '',
    labelEn: '',
  },
  serviceCost: 0,
  skills: [],
  expireAt: '',
})

export const convertListingCVRequestToState = (item: ListingCVRequestFromBE): ListingCVRequest => ({
  id: item.id,
  occupation: {
    id: 0,
    label: item.occupation.label,
    labelEn: item.occupation.label_en,
  },
  employer: item.employer_name,
  status: item.status,
  birthDate: item.birth_date,
  experienceInSaudi: item.experience_in_saudi,
  gender: item.gender,
  iqamaNumber: item.iqama_number,
  profileImage: item.laborer_profile,
  martialStatus: item.martial_status,
  name: {
    id: 0,
    label: item.name,
    labelEn: item.name_en,
  },
  city: {
    id: 0,
    label: item.city.label_ar,
    labelEn: item.city.label_en,
  },
  nationality: {
    id: 0,
    label: item.nationality.label,
    labelEn: item.nationality.label_en,
  },
  qualification: item.qualification || '-',
  religion: item.religion,
  salary: item.salary,
  employerMobile: item.employer_mobile,
  createdAt: item.created_at,
  laborerMobile: item.laborer_mobile || '-',
  serviceCost: item.transfer_service_cost,
  skills: item.skills
    ? item.skills.map((skill) => ({
        id: skill.id,
        label: skill.label_ar,
        labelEn: skill.label_en,
      }))
    : [],
  expireAt: item.expire_at || '',
})

export const getStylesForListingRequest = (status: ListingRequestStatus) => {
  switch (status) {
    case ListingRequestStatus.WAITING_LABORER_APPROVAL:
    case ListingRequestStatus.WAITING_EMPLOYER_CONFIRMATION:
    case ListingRequestStatus.WAITING_APPROVAL:
    case ListingRequestStatus.EXPIRED_LABORER_APPROVAL:
    case ListingRequestStatus.EXPIRED:
      return 'warning'
    case ListingRequestStatus.ACTIVE:
    case ListingRequestStatus.SELECTED:
    case ListingRequestStatus.TRANSFERRED:
    case ListingRequestStatus.APPROVED:
      return 'success'
    case ListingRequestStatus.REJECTED_LABORER_APPROVAL:
    case ListingRequestStatus.CANCELLED_EMPLOYER:
    case ListingRequestStatus.DEACTIVATED_EMPLOYER:
    case ListingRequestStatus.REJECTED_TRANSFER:
    case ListingRequestStatus.REJECTED:
      return 'danger'
    default:
      return ''
  }
}
