enum Actions {
  LOGIN = 'LOGIN',
  LOGIN_ASYNC = 'LOGIN_ASYNC',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAIL = 'LOGIN_FAIL',
  LOGOUT = 'LOGOUT',
  LOGOUT_ASYNC = 'LOGOUT_ASYNC',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  LOGOUT_FAIL = 'LOGOUT_FAIL',
  UPDATE_AUTH = 'UPDATE_AUTH',
  VERIFY = 'VERIFY',
  VERIFY_ASYNC = 'VERIFY_ASYNC',
  VERIFY_SUCCESS = 'VERIFY_SUCCESS',
  VERIFY_FAIL = 'VERIFY_FAIL',
  REG_VERIFY = 'REG_VERIFY',
  REG_VERIFY_ASYNC = 'REG_VERIFY_ASYNC',
  REG_VERIFY_SUCCESS = 'REG_VERIFY_SUCCESS',
  REG_VERIFY_FAIL = 'REG_VERIFY_FAIL',
  MOBILE_CHECK = 'MOBILE_CHECK',
  MOBILE_CHECK_ASYNC = 'MOBILE_CHECK_ASYNC',
  MOBILE_CHECK_SUCCESS = 'MOBILE_CHECK_SUCCESS',
  MOBILE_CHECK_FAIL = 'MOBILE_CHECK_FAIL',
  MOBILE_VERIFY = 'MOBILE_VERIFY',
  MOBILE_VERIFY_ASYNC = 'MOBILE_VERIFY_ASYNC',
  MOBILE_VERIFY_SUCCESS = 'MOBILE_VERIFY_SUCCESS',
  MOBILE_VERIFY_FAIL = 'MOBILE_VERIFY_FAIL',
  CHECK_ID = 'CHECK_ID',
  CHECK_ID_ASYNC = 'CHECK_ID_ASYNC',
  CHECK_ID_SUCCESS = 'CHECK_ID_SUCCESS',
  CHECK_ID_FAIL = 'CHECK_ID_FAIL',
  GET_MARKET_OFFERS = 'GET_MARKET_OFFERS',
  SHOW_COMPLAINTS_SURVEY = 'SHOW_COMPLAINTS_SURVEY',
  GET_MARKET_OFFERS_ASYNC = 'GET_MARKET_OFFERS_ASYNC',
  GET_MARKET_OFFERS_SUCCESS = 'GET_MARKET_OFFERS_SUCCESS',
  GET_MARKET_OFFERS_FAIL = 'GET_MARKET_OFFERS_FAIL',
  ADD_SELECTED_OFFER = 'ADD_SELECTED_OFFER',
  REMOVE_SELECTED_OFFER = 'REMOVE_SELECTED_OFFER',
  SET_MARKET_FILTER = 'SET_MARKET_FILTER',
  GET_PRICE_RANGE = 'GET_PRICE_RANGE',
  GET_PRICE_RANGE_ASYNC = 'GET_PRICE_RANGE_ASYNC',
  GET_PRICE_RANGE_SUCCESS = 'GET_PRICE_RANGE_SUCCESS',
  GET_PRICE_RANGE_FAIL = 'GET_PRICE_RANGE_FAIL',
  GET_MAAROFA_SERVICE_TEMPLATE = 'GET_MAAROFA_SERVICE_TEMPLATE',
  GET_MAAROFA_SERVICE_TEMPLATE_ASYNC = 'GET_MAAROFA_SERVICE_TEMPLATE_ASYNC',
  GET_MAAROFA_SERVICE_TEMPLATE_SUCCESS = 'GET_MAAROFA_SERVICE_TEMPLATE_SUCCESS',
  GET_MAAROFA_SERVICE_TEMPLATE_FAIL = 'GET_MAAROFA_SERVICE_TEMPLATE_FAIL',
  GET_SURVEY_QUESTIONS = 'GET_SURVEY_QUESTIONS',
  GET_SURVEY_QUESTIONS_ASYNC = 'GET_SURVEY_QUESTIONS_ASYNC',
  GET_SURVEY_QUESTIONS_SUCCESS = 'GET_SURVEY_QUESTIONS_SUCCESS',
  GET_SURVEY_QUESTIONS_FAIL = 'GET_SURVEY_QUESTIONS_FAIL',
  POST_SURVEY_QUESTIONS = 'POST_SURVEY_QUESTIONS',
  POST_SURVEY_QUESTIONS_ASYNC = 'POST_SURVEY_QUESTIONS_ASYNC',
  POST_SURVEY_QUESTIONS_SUCCESS = 'POST_SURVEY_QUESTIONS_SUCCESS',
  POST_SURVEY_QUESTIONS_FAIL = 'POST_SURVEY_QUESTIONS_FAIL',
  GET_NATIONALITIES = 'GET_NATIONALITIES',
  GET_NATIONALITIES_ASYNC = 'GET_NATIONALITIES_ASYNC',
  GET_NATIONALITIES_SUCCESS = 'GET_NATIONALITIES_SUCCESS',
  GET_NATIONALITIES_FAIL = 'GET_NATIONALITIES_FAIL',
  GET_OCCUPATIONS = 'GET_OCCUPATIONS',
  GET_OCCUPATIONS_ASYNC = 'GET_OCCUPATIONS_ASYNC',
  GET_OCCUPATIONS_SUCCESS = 'GET_OCCUPATIONS_SUCCESS',
  GET_OCCUPATIONS_FAIL = 'GET_OCCUPATIONS_FAIL',
  GET_LABOR_OFFICES = 'GET_LABOR_OFFICES',
  GET_LABOR_OFFICES_ASYNC = 'GET_LABOR_OFFICES_ASYNC',
  GET_LABOR_OFFICES_SUCCESS = 'GET_LABOR_OFFICES_SUCCESS',
  GET_LABOR_OFFICES_FAIL = 'GET_LABOR_OFFICES_FAIL',
  GET_ARRIVAL_CITIES = 'GET_ARRIVAL_CITIES',
  GET_ARRIVAL_CITIES_ASYNC = 'GET_ARRIVAL_CITIES_ASYNC',
  GET_ARRIVAL_CITIES_SUCCESS = 'GET_ARRIVAL_CITIES_SUCCESS',
  GET_ARRIVAL_CITIES_FAIL = 'GET_ARRIVAL_CITIES_FAIL',
  RESET_TOKEN = 'RESET_TOKEN',
  CHECK_ELIGIBILITY = 'CHECK_ELIGIBILITY',
  CHECK_ELIGIBILITY_ASYNC = 'CHECK_ELIGIBILITY_ASYNC',
  CHECK_ELIGIBILITY_SUCCESS = 'CHECK_ELIGIBILITY_SUCCESS',
  CHECK_ELIGIBILITY_FAIL = 'CHECK_ELIGIBILITY_FAIL',
  CHECK_ELIGIBILITY_FOR_DISABILITY_VISA = 'CHECK_ELIGIBILITY_FOR_DISABILITY_VISA',
  CHECK_ELIGIBILITY_FOR_DISABILITY_VISA_ASYNC = 'CHECK_ELIGIBILITY_FOR_DISABILITY_VISA_ASYNC',
  CHECK_ELIGIBILITY_FOR_DISABILITY_VISA_SUCCESS = 'CHECK_ELIGIBILITY_FOR_DISABILITY_VISA_SUCCESS',
  CHECK_ELIGIBILITY_FOR_DISABILITY_VISA_FAIL = 'CHECK_ELIGIBILITY_FOR_DISABILITY_VISA_FAIL',
  CHECK_ELIGIBILITY_FOR_ALTERNATIVE_VISA = 'CHECK_ELIGIBILITY_FOR_ALTERNATIVE_VISA',
  CHECK_ELIGIBILITY_FOR_ALTERNATIVE_VISA_ASYNC = 'CHECK_ELIGIBILITY_FOR_ALTERNATIVE_VISA_ASYNC',
  CHECK_ELIGIBILITY_FOR_ALTERNATIVE_VISA_SUCCESS = 'CHECK_ELIGIBILITY_FOR_ALTERNATIVE_VISA_SUCCESS',
  CHECK_ELIGIBILITY_FOR_ALTERNATIVE_VISA_FAIL = 'CHECK_ELIGIBILITY_FOR_ALTERNATIVE_VISA_FAIL',
  CHECK_ELIGIBILITY_FOR_FARMER_VISA = 'CHECK_ELIGIBILITY_FOR_FARMER_VISA',
  CHECK_ELIGIBILITY_FOR_FARMER_VISA_ASYNC = 'CHECK_ELIGIBILITY_FOR_FARMER_VISA_ASYNC',
  CHECK_ELIGIBILITY_FOR_FARMER_VISA_SUCCESS = 'CHECK_ELIGIBILITY_FOR_FARMER_VISA_SUCCESS',
  CHECK_ELIGIBILITY_FOR_FARMER_VISA_FAIL = 'CHECK_ELIGIBILITY_FOR_FARMER_VISA_FAIL',
  CANCEL_FARMER_VISA = 'CANCEL_FARMER_VISA',
  CANCEL_FARMER_VISA_ASYNC = 'CANCEL_FARMER_VISA_ASYNC',
  CANCEL_FARMER_VISA_SUCCESS = 'CANCEL_FARMER_VISA_SUCCESS',
  CANCEL_FARMER_VISA_FAIL = 'CANCEL_FARMER_VISA_FAIL',
  CHECK_AGRICULTURAL_FILE = 'CHECK_AGRICULTURAL_FILE',
  CHECK_AGRICULTURAL_FILE_ASYNC = 'CHECK_AGRICULTURAL_FILE_ASYNC',
  CHECK_AGRICULTURAL_FILE_SUCCESS = 'CHECK_AGRICULTURAL_FILE_SUCCESS',
  CHECK_AGRICULTURAL_FILE_FAIL = 'CHECK_AGRICULTURAL_FILE_FAIL',
  SUBMIT_AGRICULTURAL_FILE = 'SUBMIT_AGRICULTURAL_FILE',
  SUBMIT_AGRICULTURAL_FILE_ASYNC = 'SUBMIT_AGRICULTURAL_FILE_ASYNC',
  SUBMIT_AGRICULTURAL_FILE_SUCCESS = 'SUBMIT_AGRICULTURAL_FILE_SUCCESS',
  SUBMIT_AGRICULTURAL_FILE_FAIL = 'SUBMIT_AGRICULTURAL_FILE_FAIL',
  VISA_INQUIRY = 'VISA_INQUIRY',
  VISA_INQUIRY_ASYNC = 'VISA_INQUIRY_ASYNC',
  VISA_INQUIRY_SUCCESS = 'VISA_INQUIRY_SUCCESS',
  VISA_INQUIRY_FAIL = 'VISA_INQUIRY_FAIL',
  LOGIN_NAFATH = 'LOGIN_NAFATH',
  LOGIN_NAFATH_ASYNC = 'LOGIN_NAFATH_ASYNC',
  LOGIN_NAFATH_SUCCESS = 'LOGIN_NAFATH_SUCCESS',
  LOGIN_NAFATH_FAIL = 'LOGIN_NAFATH_FAIL',
  CHECK_NAFATH = 'CHECK_NAFATH',
  CHECK_NAFATH_ASYNC = 'CHECK_NAFATH_ASYNC',
  CHECK_NAFATH_SUCCESS = 'CHECK_NAFATH_SUCCESS',
  CHECK_NAFATH_FAIL = 'CHECK_NAFATH_FAIL',
  SEND_ABSHER_FOR_UPDATE_MOBILE = 'SEND_ABSHER_FOR_UPDATE_MOBILE',
  SEND_ABSHER_FOR_UPDATE_MOBILE_ASYNC = 'SEND_ABSHER_FOR_UPDATE_MOBILE_ASYNC',
  SEND_ABSHER_FOR_UPDATE_MOBILE_SUCCESS = 'SEND_ABSHER_FOR_UPDATE_MOBILE_SUCCESS',
  SEND_ABSHER_FOR_UPDATE_MOBILE_FAIL = 'SEND_ABSHER_FOR_UPDATE_MOBILE_FAIL',
  VERIFY_ABSHER_FOR_UPDATE_MOBILE = 'VERIFY_ABSHER_FOR_UPDATE_MOBILE',
  VERIFY_ABSHER_FOR_UPDATE_MOBILE_ASYNC = 'VERIFY_ABSHER_FOR_UPDATE_MOBILE_ASYNC',
  VERIFY_ABSHER_FOR_UPDATE_MOBILE_SUCCESS = 'VERIFY_ABSHER_FOR_UPDATE_MOBILE_SUCCESS',
  VERIFY_ABSHER_FOR_UPDATE_MOBILE_FAIL = 'VERIFY_ABSHER_FOR_UPDATE_MOBILE_FAIL',
  SEND_OTP_FOR_UPDATE_MOBILE = 'SEND_OTP_FOR_UPDATE_MOBILE',
  SEND_OTP_FOR_UPDATE_MOBILE_ASYNC = 'SEND_OTP_FOR_UPDATE_MOBILE_ASYNC',
  SEND_OTP_FOR_UPDATE_MOBILE_SUCCESS = 'SEND_OTP_FOR_UPDATE_MOBILE_SUCCESS',
  SEND_OTP_FOR_UPDATE_MOBILE_FAIL = 'SEND_OTP_FOR_UPDATE_MOBILE_FAIL',
  VERIFY_OTP_FOR_UPDATE_MOBILE = 'VERIFY_OTP_FOR_UPDATE_MOBILE',
  VERIFY_OTP_FOR_UPDATE_MOBILE_ASYNC = 'VERIFY_OTP_FOR_UPDATE_MOBILE_ASYNC',
  VERIFY_OTP_FOR_UPDATE_MOBILE_SUCCESS = 'VERIFY_OTP_FOR_UPDATE_MOBILE_SUCCESS',
  VERIFY_OTP_FOR_UPDATE_MOBILE_FAIL = 'VERIFY_OTP_FOR_UPDATE_MOBILE_FAIL',
  GET_OCCUPATION_DESCRIPTIONS = 'GET_OCCUPATION_DESCRIPTIONS',
  GET_OCCUPATION_DESCRIPTIONS_ASYNC = 'GET_OCCUPATION_DESCRIPTIONS_ASYNC',
  GET_OCCUPATION_DESCRIPTIONS_SUCCESS = 'GET_OCCUPATION_DESCRIPTIONS_SUCCESS',
  GET_OCCUPATION_DESCRIPTIONS_FAIL = 'GET_OCCUPATION_DESCRIPTIONS_FAIL',
  CHECK_LABORER_PHONE_FOR_CONTRACT_AUTH = 'CHECK_LABORER_PHONE_FOR_CONTRACT_AUTH',
  CHECK_LABORER_PHONE_FOR_CONTRACT_AUTH_ASYNC = 'CHECK_LABORER_PHONE_FOR_CONTRACT_AUTH_ASYNC',
  CHECK_LABORER_PHONE_FOR_CONTRACT_AUTH_SUCCESS = 'CHECK_LABORER_PHONE_FOR_CONTRACT_AUTH_SUCCESS',
  CHECK_LABORER_PHONE_FOR_CONTRACT_AUTH_FAIL = 'CHECK_LABORER_PHONE_FOR_CONTRACT_AUTH_FAIL',
  GET_ALL_MARKET_OFFERS = 'GET_ALL_MARKET_OFFERS',
  GET_ALL_MARKET_OFFERS_ASYNC = 'GET_ALL_MARKET_OFFERS_ASYNC',
  GET_ALL_MARKET_OFFERS_SUCCESS = 'GET_ALL_MARKET_OFFERS_SUCCESS',
  GET_ALL_MARKET_OFFERS_FAIL = 'GET_ALL_MARKET_OFFERS_FAIL',
  GET_ETAWTHEQ_CONTRACT = 'GET_ETAWTHEQ_CONTRACT',
  GET_ETAWTHEQ_CONTRACT_ASYNC = 'GET_ETAWTHEQ_CONTRACT_ASYNC',
  GET_ETAWTHEQ_CONTRACT_SUCCESS = 'GET_ETAWTHEQ_CONTRACT_SUCCESS',
  GET_ETAWTHEQ_CONTRACT_FAIL = 'GET_ETAWTHEQ_CONTRACT_FAIL',
  HAS_VISAS = 'HAS_VISAS',
  HAS_VISAS_ASYNC = 'HAS_VISAS_ASYNC',
  HAS_VISAS_SUCCESS = 'HAS_VISAS_SUCCESS',
  HAS_VISAS_FAIL = 'HAS_VISAS_FAIL',
  ACT_AS_PREMIUM = 'ACT_AS_PREMIUM',
  ACT_AS_PREMIUM_ASYNC = 'ACT_AS_PREMIUM_ASYNC',
  ACT_AS_PREMIUM_SUCCESS = 'ACT_AS_PREMIUM_SUCCESS',
  ACT_AS_PREMIUM_FAIL = 'ACT_AS_PREMIUM_FAIL',
  CREATE_CONTRACT_AUTH_REQUEST = 'CREATE_CONTRACT_AUTH_REQUEST',
  CREATE_CONTRACT_AUTH_REQUEST_ASYNC = 'CREATE_CONTRACT_AUTH_REQUEST_ASYNC',
  CREATE_CONTRACT_AUTH_REQUEST_SUCCESS = 'CREATE_CONTRACT_AUTH_REQUEST_SUCCESS',
  CREATE_CONTRACT_AUTH_REQUEST_FAIL = 'CREATE_CONTRACT_AUTH_REQUEST_FAIL',
  GET_CONTRACT_AUTH_DRAFT = 'GET_CONTRACT_AUTH_DRAFT',
  GET_CONTRACT_AUTH_DRAFT_ASYNC = 'GET_CONTRACT_AUTH_DRAFT_ASYNC',
  GET_CONTRACT_AUTH_DRAFT_SUCCESS = 'GET_CONTRACT_AUTH_DRAFT_SUCCESS',
  GET_CONTRACT_AUTH_DRAFT_FAIL = 'GET_CONTRACT_AUTH_DRAFT_FAIL',
  GET_CONTRACT_AUTH_DETAILED_REQUEST = 'GET_CONTRACT_AUTH_DETAILED_REQUEST',
  GET_CONTRACT_AUTH_DETAILED_REQUEST_ASYNC = 'GET_CONTRACT_AUTH_DETAILED_REQUEST_ASYNC',
  GET_CONTRACT_AUTH_DETAILED_REQUEST_SUCCESS = 'GET_CONTRACT_AUTH_DETAILED_REQUEST_SUCCESS',
  GET_CONTRACT_AUTH_DETAILED_REQUEST_FAIL = 'GET_CONTRACT_AUTH_DETAILED_REQUEST_FAIL',
  CANCEL_CONTRACT_AUTH_REQUEST = 'CANCEL_CONTRACT_AUTH_REQUEST',
  CANCEL_CONTRACT_AUTH_REQUEST_ASYNC = 'CANCEL_CONTRACT_AUTH_REQUEST_ASYNC',
  CANCEL_CONTRACT_AUTH_REQUEST_SUCCESS = 'CANCEL_CONTRACT_AUTH_REQUEST_SUCCESS',
  CANCEL_CONTRACT_AUTH_REQUEST_FAIL = 'CANCEL_CONTRACT_AUTH_REQUEST_FAIL',
  UPLOAD_VISAS_FILES = 'UPLOAD_VISA_FILES',
  UPLOAD_VISAS_FILES_ASYNC = 'UPLOAD_VISA_FILES_ASYNC',
  UPLOAD_VISAS_FILES_SUCCESS = 'UPLOAD_VISA_FILES_SUCCESS',
  UPLOAD_VISAS_FILES_FAIL = 'UPLOAD_VISA_FILES_FAIL',
  REMOVE_VISAS_FILE = 'REMOVE_VISA_FILE',
  UPDATE_PROGRESS = 'UPDATE_PROGRESS',
  SET_LANGUAGE = 'SET_LANGUAGE',
  SET_PREFERENCES_FORM_DATA = 'SET_PREFERENCES_FORM_DATA',
  SET_PRESELECTED_FORM_DATA = 'SET_PRESELECTED_FORM_DATA',
  RESEND = 'RESEND',
  RESEND_ASYNC = 'RESEND_ASYNC',
  RESEND_SUCCESS = 'RESEND_SUCCESS',
  RESEND_FAIL = 'RESEND_FAIL',
  RESET_LOGIN_ERROR = 'RESET_LOGIN_ERROR',
  SET_SIGN_UP_STEP = 'SET_SIGN_UP_STEP',
  RESET_AUTH = 'RESET_AUTH',
  REQUEST_RESET = 'REQUEST_RESET',
  REQUEST_RESET_ASYNC = 'REQUEST_RESET_ASYNC',
  REQUEST_RESET_SUCCESS = 'REQUEST_RESET_SUCCESS',
  REQUEST_RESET_FAIL = 'REQUEST_RESET_FAIL',
  VERIFY_RESET = 'VERIFY_RESET',
  VERIFY_RESET_ASYNC = 'VERIFY_RESET_ASYNC',
  VERIFY_RESET_SUCCESS = 'VERIFY_RESET_SUCCESS',
  VERIFY_RESET_FAIL = 'VERIFY_RESET_FAIL',
  RESET_PASSWORD = 'RESET_PASSWORD',
  RESET_PASSWORD_ASYNC = 'RESET_PASSWORD_ASYNC',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL',
  GET_DURATION_RANGE = 'GET_DURATION_RANGE',
  GET_DURATION_RANGE_ASYNC = 'GET_DURATION_RANGE_ASYNC',
  GET_DURATION_RANGE_SUCCESS = 'GET_DURATION_RANGE_SUCCESS',
  GET_DURATION_RANGE_FAIL = 'GET_DURATION_RANGE_FAIL',
  SUBMIT_VISA_WITH_CONTRACT_REQUEST = 'SUBMIT_VISA_WITH_CONTRACT_REQUEST',
  SUBMIT_VISA_WITH_CONTRACT_REQUEST_ASYNC = 'SUBMIT_VISA_WITH_CONTRACT_REQUEST_ASYNC',
  SUBMIT_VISA_WITH_CONTRACT_REQUEST_SUCCESS = 'SUBMIT_VISA_WITH_CONTRACT_REQUEST_SUCCESS',
  SUBMIT_VISA_WITH_CONTRACT_REQUEST_FAIL = 'SUBMIT_VISA_WITH_CONTRACT_REQUEST_FAIL',
  GET_CONTRACT_REQUESTS_LIST = 'GET_CONTRACT_REQUESTS_LIST',
  GET_CONTRACT_REQUESTS_LIST_ASYNC = 'GET_CONTRACT_REQUESTS_LIST_ASYNC',
  GET_CONTRACT_REQUESTS_LIST_SUCCESS = 'GET_CONTRACT_REQUESTS_LIST_SUCCESS',
  GET_CONTRACT_REQUESTS_LIST_FAIL = 'GET_CONTRACT_REQUESTS_LIST_FAIL',
  RESET_STATE = 'RESET_STATE',
  GET_CONTRACT_REQUEST_INFO = 'GET_CONTRACT_REQUEST_INFO',
  GET_CONTRACT_REQUEST_INFO_ASYNC = 'GET_CONTRACT_REQUEST_INFO_ASYNC',
  GET_CONTRACT_REQUEST_INFO_SUCCESS = 'GET_CONTRACT_REQUEST_INFO_SUCCESS',
  GET_CONTRACT_REQUEST_INFO_FAIL = 'GET_CONTRACT_REQUEST_INFO_FAIL',
  SET_CV_BY_PREFERENCES = 'SET_CV_BY_PREFERENCES',
  SEND_CV_REPORT = 'SEND_CV_REPORT',
  SEND_CV_REPORT_ASYNC = 'SEND_CV_REPORT_ASYNC',
  SEND_CV_REPORT_SUCCESS = 'SEND_CV_REPORT_SUCCESS',
  SEND_CV_REPORT_FAIL = 'SEND_CV_REPORT_FAIL',
  GET_DEFAULT_ARRIVAL_CITY = 'GET_DEFAULT_ARRIVAL_CITY',
  GET_VISA_INFO = 'GET_VISA_INFO',
  GET_VISA_INFO_ASYNC = 'GET_VISA_INFO_ASYNC',
  GET_VISA_INFO_SUCCESS = 'GET_VISA_INFO_SUCCESS',
  GET_VISA_INFO_FAIL = 'GET_VISA_INFO_FAIL',
  GET_FARMER_VISA_REQUESTS = 'GET_FARMER_VISA_REQUESTS',
  GET_FARMER_VISA_REQUESTS_ASYNC = 'GET_FARMER_VISA_REQUESTS_ASYNC',
  GET_FARMER_VISA_REQUESTS_SUCCESS = 'GET_FARMER_VISA_REQUESTS_SUCCESS',
  GET_FARMER_VISA_REQUESTS_FAIL = 'GET_FARMER_VISA_REQUESTS_FAIL',
  GET_WORKERS = 'GET_WORKERS',
  GET_WORKERS_ASYNC = 'GET_WORKERS_ASYNC',
  GET_WORKERS_SUCCESS = 'GET_WORKERS_SUCCESS',
  GET_WORKERS_FAIL = 'GET_WORKERS_FAIL',
  GET_TICKETS_LIST = 'GET_TICKETS_LIST',
  GET_TICKETS_LIST_ASYNC = 'GET_TICKETS_LIST_ASYNC',
  GET_TICKETS_LIST_SUCCESS = 'GET_TICKETS_LIST_SUCCESS',
  GET_TICKETS_LIST_FAIL = 'GET_TICKETS_LIST_FAIL',
  GET_TICKET = 'GET_TICKET',
  GET_TICKET_ASYNC = 'GET_TICKET_ASYNC',
  GET_TICKET_SUCCESS = 'GET_TICKET_SUCCESS',
  GET_TICKET_FAIL = 'GET_TICKET_FAIL',
  GET_AUTH_DATA = 'GET_AUTH_DATA',
  GET_AUTH_DATA_ASYNC = 'GET_AUTH_DATA_ASYNC',
  GET_AUTH_DATA_SUCCESS = 'GET_AUTH_DATA_SUCCESS',
  GET_AUTH_DATA_FAIL = 'GET_AUTH_DATA_FAIL',
  GET_USER_DATA = 'GET_USER_DATA',
  GET_USER_DATA_ASYNC = 'GET_USER_DATA_ASYNC',
  GET_USER_DATA_SUCCESS = 'GET_USER_DATA_SUCCESS',
  GET_USER_DATA_FAIL = 'GET_USER_DATA_FAIL',
  RESET_COMPLETED_STATE = 'RESET_COMPLETED_STATE',
  GET_PAYMENT_INFO = 'GET_PAYMENT_INFO',
  GET_PAYMENT_INFO_ASYNC = 'GET_PAYMENT_INFO_ASYNC',
  GET_PAYMENT_INFO_SUCCESS = 'GET_PAYMENT_INFO_SUCCESS',
  GET_PAYMENT_INFO_FAIL = 'GET_PAYMENT_INFO_FAIL',
  PAY_FOR_ECONTRACT = 'PAY_FOR_ECONTRACT',
  PAY_FOR_ECONTRACT_ASYNC = 'PAY_FOR_ECONTRACT_ASYNC',
  PAY_FOR_ECONTRACT_SUCCESS = 'PAY_FOR_ECONTRACT_SUCCESS',
  PAY_FOR_ECONTRACT_FAIL = 'PAY_FOR_ECONTRACT_FAIL',
  OPEN_PAYMENT_CHANNEL = 'OPEN_PAYMENT_CHANNEL',
  GET_CONTRACT_REQUEST_AND_VISA_INFO = 'GET_CONTRACT_REQUEST_AND_VISA_INFO',
  ACCEPT_OFFER_REQUEST = 'ACCEPT_OFFER_REQUEST',
  VIP_ACCEPT_OFFER_REQUEST = 'VIP_ACCEPT_OFFER_REQUEST',
  ACCEPT_OFFER_REQUEST_ASYNC = 'ACCEPT_OFFER_REQUEST_ASYNC',
  ACCEPT_OFFER_REQUEST_SUCCESS = 'ACCEPT_OFFER_REQUEST_SUCCESS',
  ACCEPT_OFFER_REQUEST_FAIL = 'ACCEPT_OFFER_REQUEST_FAIL',
  PAY_FOR_FARMER_VISA = 'PAY_FOR_FARMER_VISA',
  PAY_FOR_FARMER_VISA_ASYNC = 'PAY_FOR_FARMER_VISA_ASYNC',
  PAY_FOR_FARMER_VISA_SUCCESS = 'PAY_FOR_FARMER_VISA_SUCCESS',
  PAY_FOR_FARMER_VISA_FAIL = 'PAY_FOR_FARMER_VISA_FAIL',
  GET_VISA_INVOICE_FILE = 'GET_VISA_INVOICE_FILE',
  GET_VISA_INVOICE_FILE_ASYNC = 'GET_VISA_INVOICE_FILE_ASYNC',
  GET_VISA_INVOICE_FILE_SUCCESS = 'GET_VISA_INVOICE_FILE_SUCCESS',
  GET_VISA_INVOICE_FILE_FAIL = 'GET_VISA_INVOICE_FILE_FAIL',
  GET_VISAS_LIST = 'GET_VISAS_LIST',
  GET_VISAS_LIST_ASYNC = 'GET_VISAS_LIST_ASYNC',
  GET_VISAS_LIST_SUCCESS = 'GET_VISAS_LIST_SUCCESS',
  GET_VISAS_LIST_FAIL = 'GET_VISAS_LIST_FAIL',
  GET_CONTRACT_AND_PREPARE_PAYMENT = 'GET_CONTRACT_REQUEST_AND_PAYMENT_INFO',
  GET_VISA_ISSUE_REQUESTS_LIST = 'GET_VISA_ISSUE_REQUESTS_LIST',
  GET_VISA_ISSUE_REQUESTS_LIST_ASYNC = 'GET_VISA_ISSUE_REQUESTS_LIST_ASYNC',
  GET_VISA_ISSUE_REQUESTS_LIST_SUCCESS = 'GET_VISA_ISSUE_REQUESTS_LIST_SUCCESS',
  GET_VISA_ISSUE_REQUESTS_LIST_FAIL = 'GET_VISA_ISSUE_REQUESTS_LIST_FAIL',
  GET_AVAILABLE_VISAS = 'GET_AVAILABLE_VISAS',
  GET_AVAILABLE_VISAS_ASYNC = 'GET_AVAILABLE_VISAS_ASYNC',
  GET_AVAILABLE_VISAS_SUCCESS = 'GET_AVAILABLE_VISAS_SUCCESS',
  GET_AVAILABLE_VISAS_FAIL = 'GET_AVAILABLE_VISAS_FAIL',
  SET_MAAROFA_DATA = 'SET_MAAROFA_DATA',
  MAAROFA_PRESUBMIT = 'MAAROFA_PRESUBMIT',
  MAAROFA_PRESUBMIT_ASYNC = 'MAAROFA_PRESUBMIT_ASYNC',
  MAAROFA_PRESUBMIT_SUCCESS = 'MAAROFA_PRESUBMIT_SUCCESS',
  MAAROFA_PRESUBMIT_FAIL = 'MAAROFA_PRESUBMIT_FAIL',
  MAAROFA_SUBMIT = 'MAAROFA_SUBMIT',
  MAAROFA_SUBMIT_ASYNC = 'MAAROFA_SUBMIT_ASYNC',
  MAAROFA_SUBMIT_SUCCESS = 'MAAROFA_SUBMIT_SUCCESS',
  MAAROFA_SUBMIT_FAIL = 'MAAROFA_SUBMIT_FAIL',
  GET_MAAROFA_OFFICES = 'GET_MAAROFA_OFFICES',
  GET_MAAROFA_OFFICES_ASYNC = 'GET_MAAROFA_OFFICES_ASYNC',
  GET_MAAROFA_OFFICES_SUCCESS = 'GET_MAAROFA_OFFICES_SUCCESS',
  GET_MAAROFA_OFFICES_FAIL = 'GET_MAAROFA_OFFICES_FAIL',
  GET_NOTICES_LIST = 'GET_NOTICES_LIST',
  GET_NOTICES_LIST_ASYNC = 'GET_NOTICES_LIST_ASYNC',
  GET_NOTICES_LIST_SUCCESS = 'GET_NOTICES_LIST_SUCCESS',
  GET_NOTICES_LIST_FAIL = 'GET_NOTICES_LIST_FAIL',
  CREATE_NOTICE = 'CREATE_NOTICE',
  CREATE_NOTICE_ASYNC = 'CREATE_NOTICE_ASYNC',
  CREATE_NOTICE_SUCCESS = 'CREATE_NOTICE_SUCCESS',
  CREATE_NOTICE_FAIL = 'CREATE_NOTICE_FAIL',
  GET_BANKS_LIST = 'GET_BANKS_LIST',
  GET_BANKS_LIST_ASYNC = 'GET_BANKS_LIST_ASYNC',
  GET_BANKS_LIST_SUCCESS = 'GET_BANKS_LIST_SUCCESS',
  GET_BANKS_LIST_FAIL = 'GET_BANKS_LIST_FAIL',
  CHECK_NOTICE_AVAILABILITY = 'CHECK_NOTICE_AVAILABILITY',
  CHECK_NOTICE_AVAILABILITY_ASYNC = 'CHECK_NOTICE_AVAILABILITY_ASYNC',
  CHECK_NOTICE_AVAILABILITY_SUCCESS = 'CHECK_NOTICE_AVAILABILITY_SUCCESS',
  CHECK_NOTICE_AVAILABILITY_FAIL = 'CHECK_NOTICE_AVAILABILITY_FAIL',
  GET_NOTICE = 'GET_NOTICE',
  GET_NOTICE_ASYNC = 'GET_NOTICE_ASYNC',
  GET_NOTICE_SUCCESS = 'GET_NOTICE_SUCCESS',
  GET_NOTICE_FAIL = 'GET_NOTICE_FAIL',
  PAY_NOTICE = 'PAY_NOTICE',
  PAY_NOTICE_ASYNC = 'PAY_NOTICE_ASYNC',
  PAY_NOTICE_SUCCESS = 'PAY_NOTICE_SUCCESS',
  PAY_NOTICE_FAIL = 'PAY_NOTICE_FAIL',
  CANCEL_CONTRACT = 'CANCEL_CONTRACT',
  CANCEL_CONTRACT_ASYNC = 'CANCEL_CONTRACT_ASYNC',
  CANCEL_CONTRACT_SUCCESS = 'CANCEL_CONTRACT_SUCCESS',
  CANCEL_CONTRACT_FAIL = 'CANCEL_CONTRACT_FAIL',
  REJECT_OFFER = 'REJECT_OFFER',
  REJECT_OFFER_ASYNC = 'REJECT_OFFER_ASYNC',
  REJECT_OFFER_SUCCESS = 'REJECT_OFFER_SUCCESS',
  REJECT_OFFER_FAIL = 'REJECT_OFFER_FAIL',
  GET_OFFICE_INFO = 'GET_OFFICE_INFO',
  GET_OFFICE_INFO_ASYNC = 'GET_OFFICE_INFO_ASYNC',
  GET_OFFICE_INFO_SUCCESS = 'GET_OFFICE_INFO_SUCCESS',
  GET_OFFICE_INFO_FAIL = 'GET_OFFICE_INFO_FAIL',
  GET_TICKET_AND_CORRESPONDING_OFFICE_INFO = 'GET_TICKET_AND_CORRESPONDING_OFFICE_INFO',
  GET_LOCATION_DETAILS = 'GET_LOCATION_DETAILS',
  GET_LOCATION_DETAILS_ASYNC = 'GET_LOCATION_DETAILS_ASYNC',
  GET_LOCATION_DETAILS_SUCCESS = 'GET_LOCATION_DETAILS_SUCCESS',
  GET_LOCATION_DETAILS_FAIL = 'GET_LOCATION_DETAILS_FAIL',
  SAVE_USER_DATA = 'SAVE_USER_DATA',
  SAVE_USER_DATA_ASYNC = 'SAVE_USER_DATA_ASYNC',
  SAVE_USER_DATA_SUCCESS = 'SAVE_USER_DATA_SUCCESS',
  SAVE_USER_DATA_FAIL = 'SAVE_USER_DATA_FAIL',
  UPDATE_PERSONAL_INFO = 'UPDATE_PERSONAL_INFO',
  UPDATE_PERSONAL_INFO_ASYNC = 'UPDATE_PERSONAL_INFO_ASYNC',
  UPDATE_PERSONAL_INFO_SUCCESS = 'UPDATE_PERSONAL_INFO_SUCCESS',
  UPDATE_PERSONAL_INFO_FAIL = 'UPDATE_PERSONAL_INFO_FAIL',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_ASYNC = 'CHANGE_PASSWORD_ASYNC',
  CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL',
  GET_VISA_ISSUE_PLACES = 'GET_VISA_ISSUE_PLACES',
  GET_VISA_ISSUE_PLACES_ASYNC = 'GET_VISA_ISSUE_PLACES_ASYNC',
  GET_VISA_ISSUE_PLACES_SUCCESS = 'GET_VISA_ISSUE_PLACES_SUCCESS',
  GET_VISA_ISSUE_PLACES_FAIL = 'GET_VISA_ISSUE_PLACES_FAIL',
  ISSUE_NEW_VISA_REQUEST = 'ISSUE_NEW_VISA_REQUEST',
  ISSUE_NEW_VISA_REQUEST_ASYNC = 'ISSUE_NEW_VISA_REQUEST_ASYNC',
  ISSUE_NEW_VISA_REQUEST_SUCCESS = 'ISSUE_NEW_VISA_REQUEST_SUCCESS',
  ISSUE_NEW_VISA_REQUEST_FAIL = 'ISSUE_NEW_VISA_REQUEST_FAIL',
  RESPOND_TO_COMPLAINT_SOLUTION = 'RESPOND_TO_COMPLAINT_SOLUTION',
  RESPOND_TO_COMPLAINT_SOLUTION_ASYNC = 'RESPOND_TO_COMPLAINT_SOLUTION_ASYNC',
  RESPOND_TO_COMPLAINT_SOLUTION_SUCCESS = 'RESPOND_TO_COMPLAINT_SOLUTION_SUCCESS',
  RESPOND_TO_COMPLAINT_SOLUTION_FAIL = 'RESPOND_TO_COMPLAINT_SOLUTION_FAIL',
  RESPOND_TO_OFFICE_COMPLAINT = 'RESPOND_TO_OFFICE_COMPLAINT',
  RESPOND_TO_OFFICE_COMPLAINT_ASYNC = 'RESPOND_TO_OFFICE_COMPLAINT_ASYNC',
  RESPOND_TO_OFFICE_COMPLAINT_SUCCESS = 'RESPOND_TO_OFFICE_COMPLAINT_SUCCESS',
  RESPOND_TO_OFFICE_COMPLAINT_FAIL = 'RESPOND_TO_OFFICE_COMPLAINT_FAIL',
  GET_E_TO_I_LEGACY_REQUESTS = 'GET_E_TO_I_LEGACY_REQUESTS',
  GET_E_TO_I_LEGACY_REQUESTS_ASYNC = 'GET_E_TO_I_LEGACY_REQUESTS_ASYNC',
  GET_E_TO_I_LEGACY_REQUESTS_SUCCESS = 'GET_E_TO_I_LEGACY_REQUESTS_SUCCESS',
  GET_E_TO_I_LEGACY_REQUESTS_FAIL = 'GET_E_TO_I_LEGACY_REQUESTS_FAIL',
  GET_E_TO_I_REQUESTS = 'GET_E_TO_I_REQUESTS',
  GET_E_TO_I_REQUESTS_ASYNC = 'GET_E_TO_I_REQUESTS_ASYNC',
  GET_E_TO_I_REQUESTS_SUCCESS = 'GET_E_TO_I_REQUESTS_SUCCESS',
  GET_E_TO_I_REQUESTS_FAIL = 'GET_E_TO_I_REQUESTS_FAIL',
  GET_E_TO_I_REQUEST_DETAILS = 'GET_E_TO_I_REQUEST_DETAILS',
  GET_E_TO_I_REQUEST_DETAILS_ASYNC = 'GET_E_TO_I_REQUEST_DETAILS_ASYNC',
  GET_E_TO_I_REQUEST_DETAILS_SUCCESS = 'GET_E_TO_I_REQUEST_DETAILS_SUCCESS',
  GET_E_TO_I_REQUEST_DETAILS_FAIL = 'GET_E_TO_I_REQUEST_DETAILS_FAIL',
  REJECT_E_TO_I_OFFER = 'REJECT_E_TO_I_OFFER',
  REJECT_E_TO_I_OFFER_ASYNC = 'REJECT_E_TO_I_OFFER_ASYNC',
  REJECT_E_TO_I_OFFER_SUCCESS = 'REJECT_E_TO_I_OFFER_SUCCESS',
  REJECT_E_TO_I_OFFER_FAIL = 'REJECT_E_TO_I_OFFER_FAIL',
  ACCEPT_E_TO_I_OFFER = 'ACCEPT_E_TO_I_OFFER',
  ACCEPT_E_TO_I_OFFER_ASYNC = 'ACCEPT_E_TO_I_OFFER_ASYNC',
  ACCEPT_E_TO_I_OFFER_SUCCESS = 'ACCEPT_E_TO_I_OFFER_SUCCESS',
  ACCEPT_E_TO_I_OFFER_FAIL = 'ACCEPT_E_TO_I_OFFER_FAIL',
  ADD_E_TO_I_FINANCIAL_DOCUMENTS = 'ADD_E_TO_I_FINANCIAL_DOCUMENTS',
  ADD_E_TO_I_FINANCIAL_DOCUMENTS_ASYNC = 'ADD_E_TO_I_FINANCIAL_DOCUMENTS_ASYNC',
  ADD_E_TO_I_FINANCIAL_DOCUMENTS_SUCCESS = 'ADD_E_TO_I_FINANCIAL_DOCUMENTS_SUCCESS',
  ADD_E_TO_I_FINANCIAL_DOCUMENTS_FAIL = 'ADD_E_TO_I_FINANCIAL_DOCUMENTS_FAIL',
  CANCEL_E_TO_I_CONTRACT = 'CANCEL_E_TO_I_CONTRACT',
  CANCEL_E_TO_I_CONTRACT_ASYNC = 'CANCEL_E_TO_I_CONTRACT_ASYNC',
  CANCEL_E_TO_I_CONTRACT_SUCCESS = 'CANCEL_E_TO_I_CONTRACT_SUCCESS',
  CANCEL_E_TO_I_CONTRACT_FAIL = 'CANCEL_E_TO_I_CONTRACT_FAIL',
  ADD_FEEDBACK = 'ADD_FEEDBACK',
  ADD_FEEDBACK_ASYNC = 'ADD_FEEDBACK_ASYNC',
  ADD_FEEDBACK_SUCCESS = 'ADD_FEEDBACK_SUCCESS',
  ADD_FEEDBACK_FAIL = 'ADD_FEEDBACK_FAIL',
  E_TO_I_TRANSFER_WORKER = 'E_TO_I_TRANSFER_WORKER',
  E_TO_I_TRANSFER_WORKER_ASYNC = 'E_TO_I_TRANSFER_WORKER_ASYNC',
  E_TO_I_TRANSFER_WORKER_SUCCESS = 'E_TO_I_TRANSFER_WORKER_SUCCESS',
  E_TO_I_TRANSFER_WORKER_FAIL = 'E_TO_I_TRANSFER_WORKER_FAIL',
  E_TO_I_REPLACE_WORKER = 'E_TO_I_REPLACE_WORKER',
  E_TO_I_REPLACE_WORKER_ASYNC = 'E_TO_I_REPLACE_WORKER_ASYNC',
  E_TO_I_REPLACE_WORKER_SUCCESS = 'E_TO_I_REPLACE_WORKER_SUCCESS',
  E_TO_I_REPLACE_WORKER_FAIL = 'E_TO_I_REPLACE_WORKER_FAIL',
  E_TO_I_CANCEL_REPLACEMENT = 'E_TO_I_CANCEL_REPLACEMENT',
  E_TO_I_CANCEL_REPLACEMENT_ASYNC = 'E_TO_I_CANCEL_REPLACEMENT_ASYNC',
  E_TO_I_CANCEL_REPLACEMENT_SUCCESS = 'E_TO_I_CANCEL_REPLACEMENT_SUCCESS',
  E_TO_I_CANCEL_REPLACEMENT_FAIL = 'E_TO_I_CANCEL_REPLACEMENT_FAIL',
  PAY_FOR_E_TO_I_REQUEST = 'PAY_FOR_E_TO_I_REQUEST',
  PAY_FOR_E_TO_I_REQUEST_ASYNC = 'PAY_FOR_E_TO_I_REQUEST_ASYNC',
  PAY_FOR_E_TO_I_REQUEST_SUCCESS = 'PAY_FOR_E_TO_I_REQUEST_SUCCESS',
  PAY_FOR_E_TO_I_REQUEST_FAIL = 'PAY_FOR_E_TO_I_REQUEST_FAIL',
  GET_LABORER_INFO = 'GET_LABORER_INFO',
  GET_LABORER_INFO_ASYNC = 'GET_LABORER_INFO_ASYNC',
  GET_LABORER_INFO_SUCCESS = 'GET_LABORER_INFO_SUCCESS',
  GET_LABORER_INFO_FAIL = 'GET_LABORER_INFO_FAIL',
  CREATE_DOMESTIC_TRANSFER_REQUEST = 'CREATE_DOMESTIC_TRANSFER_REQUEST',
  CREATE_DOMESTIC_TRANSFER_REQUEST_ASYNC = 'CREATE_DOMESTIC_TRANSFER_REQUEST_ASYNC',
  CREATE_DOMESTIC_TRANSFER_REQUEST_SUCCESS = 'CREATE_DOMESTIC_TRANSFER_REQUEST_SUCCESS',
  CREATE_DOMESTIC_TRANSFER_REQUEST_FAIL = 'CREATE_DOMESTIC_TRANSFER_REQUEST_FAIL',
  SET_ALTERNATIVE_VISA_FLOW_DATA = 'SET_ALTERNATIVE_VISA_FLOW_DATA',
  GET_ELIGIBLE_WORKERS = 'GET_ELIGIBLE_WORKERS',
  GET_ELIGIBLE_WORKERS_ASYNC = 'GET_ELIGIBLE_WORKERS_ASYNC',
  GET_ELIGIBLE_WORKERS_SUCCESS = 'GET_ELIGIBLE_WORKERS_SUCCESS',
  GET_ELIGIBLE_WORKERS_FAIL = 'GET_ELIGIBLE_WORKERS_FAIL',
  GET_REPLACEABLE_VISA = 'GET_REPLACEABLE_VISA',
  GET_REPLACEABLE_VISA_ASYNC = 'GET_REPLACEABLE_VISA_ASYNC',
  GET_REPLACEABLE_VISA_SUCCESS = 'GET_REPLACEABLE_VISA_SUCCESS',
  GET_REPLACEABLE_VISA_FAIL = 'GET_REPLACEABLE_VISA_FAIL',
  REPLACE_VISAS = 'REPLACE_VISAS',
  REPLACE_VISAS_ASYNC = 'REPLACE_VISAS_ASYNC',
  REPLACE_VISAS_SUCCESS = 'REPLACE_VISAS_SUCCESS',
  REPLACE_VISAS_FAIL = 'REPLACE_VISAS_FAIL',
  GET_SKILLS = 'GET_SKILLS',
  GET_SKILLS_ASYNC = 'GET_SKILLS_ASYNC',
  GET_SKILLS_SUCCESS = 'GET_SKILLS_SUCCESS',
  GET_SKILLS_FAIL = 'GET_SKILLS_FAIL',
  GET_REJECTION_REASONS_LIST = 'GET_REJECTION_REASONS_LIST',
  GET_REJECTION_REASONS_LIST_ASYNC = 'GET_REJECTION_REASONS_LIST_ASYNC',
  GET_REJECTION_REASONS_LIST_SUCCESS = 'GET_REJECTION_REASONS_LIST_SUCCESS',
  GET_REJECTION_REASONS_LIST_FAIL = 'GET_REJECTION_REASONS_LIST_FAIL',
  CANCEL_VISA = 'CANCEL_VISA',
  CANCEL_VISA_ASYNC = 'CANCEL_VISA_ASYNC',
  CANCEL_VISA_SUCCESS = 'CANCEL_VISA_SUCCESS',
  CANCEL_VISA_FAIL = 'CANCEL_VISA_FAIL',
  GET_SUPPORT_TICKETS_LIST = 'GET_SUPPORT_TICKETS_LIST',
  GET_SUPPORT_TICKETS_LIST_ASYNC = 'GET_SUPPORT_TICKETS_LIST_ASYNC',
  GET_SUPPORT_TICKETS_LIST_SUCCESS = 'GET_SUPPORT_TICKETS_LIST_SUCCESS',
  GET_SUPPORT_TICKETS_LIST_FAIL = 'GET_SUPPORT_TICKETS_LIST_FAIL',
  CREATE_SUPPORT_TICKET = 'CREATE_SUPPORT_TICKET',
  CREATE_SUPPORT_TICKET_ASYNC = 'CREATE_SUPPORT_TICKET_ASYNC',
  CREATE_SUPPORT_TICKET_SUCCESS = 'CREATE_SUPPORT_TICKET_SUCCESS',
  CREATE_SUPPORT_TICKET_FAIL = 'CREATE_SUPPORT_TICKET_FAIL',
  GET_SUPPORT_TICKET_DETAILS = 'GET_SUPPORT_TICKET_DETAILS',
  GET_SUPPORT_TICKET_DETAILS_ASYNC = 'GET_SUPPORT_TICKET_DETAILS_ASYNC',
  GET_SUPPORT_TICKET_DETAILS_SUCCESS = 'GET_SUPPORT_TICKET_DETAILS_SUCCESS',
  GET_SUPPORT_TICKET_DETAILS_FAIL = 'GET_SUPPORT_TICKET_DETAILS_FAIL',
  UPDATE_SUPPORT_TICKET_DETAILS = 'UPDATE_SUPPORT_TICKET_DETAILS',
  UPDATE_SUPPORT_TICKET_DETAILS_ASYNC = 'UPDATE_SUPPORT_TICKET_DETAILS_ASYNC',
  UPDATE_SUPPORT_TICKET_DETAILS_SUCCESS = 'UPDATE_SUPPORT_TICKET_DETAILS_SUCCESS',
  UPDATE_SUPPORT_TICKET_DETAILS_FAIL = 'UPDATE_SUPPORT_TICKET_DETAILS_FAIL',
  CREATE_COMPLAINT_TICKET = 'CREATE_COMPLAINT_TICKET',
  CREATE_COMPLAINT_TICKET_ASYNC = 'CREATE_COMPLAINT_TICKET_ASYNC',
  CREATE_COMPLAINT_TICKET_SUCCESS = 'CREATE_COMPLAINT_TICKET_SUCCESS',
  CREATE_COMPLAINT_TICKET_FAIL = 'CREATE_COMPLAINT_TICKET_FAIL',
  CANCEL_REQUEST = 'CANCEL_REQUEST',
  CANCEL_REQUEST_ASYNC = 'CANCEL_REQUEST_ASYNC',
  CANCEL_REQUEST_SUCCESS = 'CANCEL_REQUEST_SUCCESS',
  CANCEL_REQUEST_FAIL = 'CANCEL_REQUEST_FAIL',
  SET_REGULAR_VISA_FLOW_DATA = 'SET_REGULAR_VISA_FLOW_DATA',
  SET_DISABILITY_VISA_FLOW_DATA = 'SET_DISABILITY_VISA_FLOW_DATA',
  ISSUE_NEW_DISABILITY_VISA = 'ISSUE_NEW_DISABILITY_VISA',
  ISSUE_NEW_DISABILITY_VISA_ASYNC = 'ISSUE_NEW_DISABILITY_VISA_ASYNC',
  ISSUE_NEW_DISABILITY_VISA_SUCCESS = 'ISSUE_NEW_DISABILITY_VISA_SUCCESS',
  ISSUE_NEW_DISABILITY_VISA_FAIL = 'ISSUE_NEW_DISABILITY_VISA_FAIL',
  GET_VISA_ISSUE_REQUEST_INFO = 'GET_VISA_ISSUE_REQUEST_INFO',
  GET_VISA_ISSUE_REQUEST_INFO_ASYNC = 'GET_VISA_ISSUE_REQUEST_INFO_ASYNC',
  GET_VISA_ISSUE_REQUEST_INFO_SUCCESS = 'GET_VISA_ISSUE_REQUEST_INFO_SUCCESS',
  GET_VISA_ISSUE_REQUEST_INFO_FAIL = 'GET_VISA_ISSUE_REQUEST_INFO_FAIL',
  ONLINE_PAYMENT_VISA = 'ONLINE_PAYMENT_VISA',
  ONLINE_PAYMENT_VISA_ASYNC = 'ONLINE_PAYMENT_VISA_ASYNC',
  ONLINE_PAYMENT_VISA_SUCCESS = 'ONLINE_PAYMENT_VISA_SUCCESS',
  ONLINE_PAYMENT_VISA_FAIL = 'ONLINE_PAYMENT_VISA_FAIL',
  EXTEND_CONTRACT = 'EXTEND_CONTRACT',
  EXTEND_CONTRACT_ASYNC = 'EXTEND_CONTRACT_ASYNC',
  EXTEND_CONTRACT_SUCCESS = 'EXTEND_CONTRACT_SUCCESS',
  EXTEND_CONTRACT_FAIL = 'EXTEND_CONTRACT_FAIL',
  GET_VAT_VALUE = 'GET_VAT_VALUE',
  GET_VAT_VALUE_ASYNC = 'GET_VAT_VALUE_ASYNC',
  GET_VAT_VALUE_SUCCESS = 'GET_VAT_VALUE_SUCCESS',
  GET_VAT_VALUE_FAIL = 'GET_VAT_VALUE_FAIL',
  GET_COMPLAINT_RATINGS = 'GET_COMPLAINT_RATINGS',
  GET_COMPLAINT_RATINGS_ASYNC = 'GET_COMPLAINT_RATINGS_ASYNC',
  GET_COMPLAINT_RATINGS_SUCCESS = 'GET_COMPLAINT_RATINGS_SUCCESS',
  GET_COMPLAINT_RATINGS_FAIL = 'GET_COMPLAINT_RATINGS_FAIL',
  GET_COMPLAINT_RATINGS_V2 = 'GET_COMPLAINT_RATINGS_V2',
  GET_COMPLAINT_RATINGS_V2_ASYNC = 'GET_COMPLAINT_RATINGS_V2_ASYNC',
  GET_COMPLAINT_RATINGS_V2_SUCCESS = 'GET_COMPLAINT_RATINGS_V2_SUCCESS',
  GET_COMPLAINT_RATINGS_V2_FAIL = 'GET_COMPLAINT_RATINGS_V2_FAIL',
  MAKE_REFUND_SADAD = 'MAKE_REFUND_SADAD',
  MAKE_REFUND_SADAD_ASYNC = 'MAKE_REFUND_SADAD_ASYNC',
  MAKE_REFUND_SADAD_SUCCESS = 'MAKE_REFUND_SADAD_SUCCESS',
  MAKE_REFUND_SADAD_FAIL = 'MAKE_REFUND_SADAD_FAIL',
  MAKE_REFUND_PAY_FORT = 'MAKE_REFUND_PAY_FORT',
  MAKE_REFUND_PAY_FORT_ASYNC = 'MAKE_REFUND_PAY_FORT_ASYNC',
  MAKE_REFUND_PAY_FORT_SUCCESS = 'MAKE_REFUND_PAY_FORT_SUCCESS',
  MAKE_REFUND_PAY_FORT_FAIL = 'MAKE_REFUND_PAY_FORT_FAIL',
  GET_REFUNDABLE_BILLS = 'GET_REFUNDABLE_BILLS',
  GET_REFUNDABLE_BILLS_ASYNC = 'GET_REFUNDABLE_BILLS_ASYNC',
  GET_REFUNDABLE_BILLS_SUCCESS = 'GET_REFUNDABLE_BILLS_SUCCESS',
  GET_REFUNDABLE_BILLS_FAIL = 'GET_REFUNDABLE_BILLS_FAIL',
  CHECK_OUTDATED_IBAN = 'CHECK_OUTDATED_IBAN',
  CHECK_OUTDATED_IBAN_ASYNC = 'CHECK_OUTDATED_IBAN_ASYNC',
  CHECK_OUTDATED_IBAN_SUCCESS = 'CHECK_OUTDATED_IBAN_SUCCESS',
  CHECK_OUTDATED_IBAN_FAIL = 'CHECK_OUTDATED_IBAN_FAIL',
  UPDATE_DOMESTIC_TRANSFER_FORM = 'UPDATE_DOMESTIC_TRANSFER_FORM',
  SET_NEW_NOTICE_FLOW_DATA = 'SET_NEW_NOTICE_FLOW_DATA',
  SEND_PAYMENT_SURVEY = 'SEND_PAYMENT_SURVEY',
  SELECT_PREVIOUSLY_UPLOADED = 'SELECT_PREVIOUSLY_UPLOADED',
  GET_DOWNLOAD_HISTORY = 'GET_DOWNLOAD_HISTORY',
  GET_DOWNLOAD_HISTORY_DETAILS = 'GET_DOWNLOAD_HISTORY_DETAILS',
  GET_DOWNLOAD_HISTORY_DETAILS_ASYNC = 'GET_DOWNLOAD_HISTORY_DETAILS_ASYNC',
  GET_DOWNLOAD_HISTORY_DETAILS_SUCCESS = 'GET_DOWNLOAD_HISTORY_DETAILS_SUCCESS',
  GET_DOWNLOAD_HISTORY_DETAILS_FAIL = 'GET_DOWNLOAD_HISTORY_DETAILS_FAIL',
  SET_FARMER_VISA_FLOW_DATA = 'SET_FARMER_VISA_FLOW_DATA',
  ISSUE_NEW_FARMER_VISA = 'ISSUE_NEW_FARMER_VISA',
  ISSUE_NEW_FARMER_VISA_ASYNC = 'ISSUE_NEW_FARMER_VISA_ASYNC',
  ISSUE_NEW_FARMER_VISA_SUCCESS = 'ISSUE_NEW_FARMER_VISA_SUCCESS',
  ISSUE_NEW_FARMER_VISA_FAIL = 'ISSUE_NEW_FARMER_VISA_FAIL',
  GET_INFO_ABOUT_FARMER_REQUEST = 'GET_INFO_ABOUT_FARMER_REQUEST',
  GET_INFO_ABOUT_FARMER_REQUEST_ASYNC = 'GET_INFO_ABOUT_FARMER_REQUEST_ASYNC',
  GET_INFO_ABOUT_FARMER_REQUEST_SUCCESS = 'GET_INFO_ABOUT_FARMER_REQUEST_SUCCESS',
  GET_INFO_ABOUT_FARMER_REQUEST_FAIL = 'GET_INFO_ABOUT_FARMER_REQUEST_FAIL',
  GET_LABORS = 'GET_LABORS',
  GET_LABORS_ASYNC = 'GET_LABORS_ASYNC',
  GET_LABORS_SUCCESS = 'GET_LABORS_SUCCESS',
  GET_LABORS_FAIL = 'GET_LABORS_FAIL',
  GET_CONTRACT_AUTH_REQUESTS_LIST = 'GET_CONTRACT_AUTH_REQUESTS_LIST',
  GET_CONTRACT_AUTH_REQUESTS_LIST_ASYNC = 'GET_CONTRACT_AUTH_REQUESTS_LIST_ASYNC',
  GET_CONTRACT_AUTH_REQUESTS_LIST_SUCCESS = 'GET_CONTRACT_AUTH_REQUESTS_LIST_SUCCESS',
  GET_CONTRACT_AUTH_REQUESTS_LIST_FAIL = 'GET_CONTRACT_AUTH_REQUESTS_LIST_FAIL',
  UPDATE_LABORS = 'UPDATE_LABORS',
  UPDATE_LABORS_ASYNC = 'UPDATE_LABORS_ASYNC',
  UPDATE_LABORS_SUCCESS = 'UPDATE_LABORS_SUCCESS',
  UPDATE_LABORS_FAIL = 'UPDATE_LABORS_FAIL',
  KEEP_ALIVE = 'KEEP_ALIVE',
  KEEP_ALIVE_ASYNC = 'KEEP_ALIVE_ASYNC',
  KEEP_ALIVE_SUCCESS = 'KEEP_ALIVE_SUCCESS',
  KEEP_ALIVE_FAIL = 'KEEP_ALIVE_FAIL',
  CLOSE_COOKIES_DISCLAIMER_BAR = 'CLOSE_COOKIES_DISCLAIMER_BAR',
  CLOSE_MAAROFA_BAR = 'CLOSE_MAAROFA_BAR',
  GET_PRO_STATISTIC = 'GET_PRO_STATISTIC',
  GET_PRO_STATISTIC_ASYNC = 'GET_PRO_STATISTIC_ASYNC',
  GET_PRO_STATISTIC_SUCCESS = 'GET_PRO_STATISTIC_SUCCESS',
  GET_PRO_STATISTIC_FAIL = 'GET_PRO_STATISTIC_FAIL',
  GET_BANKS_INFO = 'GET_BANKS_INFO',
  GET_BANKS_INFO_ASYNC = 'GET_BANKS_INFO_ASYNC',
  GET_BANKS_INFO_SUCCESS = 'GET_BANKS_INFO_SUCCESS',
  GET_BANKS_INFO_FAIL = 'GET_BANKS_INFO_FAIL',
  CHECK_FARMER_TRANSFER_ELIGIBILITY = 'CHECK_FARMER_TRANSFER_ELIGIBILITY',
  CHECK_FARMER_TRANSFER_ELIGIBILITY_ASYNC = 'CHECK_FARMER_TRANSFER_ELIGIBILITY_ASYNC',
  CHECK_FARMER_TRANSFER_ELIGIBILITY_SUCCESS = 'CHECK_FARMER_TRANSFER_ELIGIBILITY_SUCCESS',
  CHECK_FARMER_TRANSFER_ELIGIBILITY_FAIL = 'CHECK_FARMER_TRANSFER_ELIGIBILITY_FAIL',
  CHECK_I_TO_I_TRANSFER_ELIGIBILITY = 'CHECK_I_TO_I_TRANSFER_ELIGIBILITY',
  CHECK_I_TO_I_TRANSFER_ELIGIBILITY_ASYNC = 'CHECK_I_TO_I_TRANSFER_ELIGIBILITY_ASYNC',
  CHECK_I_TO_I_TRANSFER_ELIGIBILITY_SUCCESS = 'CHECK_I_TO_I_TRANSFER_ELIGIBILITY_SUCCESS',
  CHECK_I_TO_I_TRANSFER_ELIGIBILITY_FAIL = 'CHECK_I_TO_I_TRANSFER_ELIGIBILITY_FAIL',
  SUBMIT_I_TO_I_TRANSFER_REQUEST = 'SUBMIT_I_TO_I_TRANSFER_REQUEST',
  SUBMIT_I_TO_I_TRANSFER_REQUEST_ASYNC = 'SUBMIT_I_TO_I_TRANSFER_REQUEST_ASYNC',
  SUBMIT_I_TO_I_TRANSFER_REQUEST_SUCCESS = 'SUBMIT_I_TO_I_TRANSFER_REQUEST_SUCCESS',
  SUBMIT_I_TO_I_TRANSFER_REQUEST_FAIL = 'SUBMIT_I_TO_I_TRANSFER_REQUEST_FAIL',
  CHECK_FARMER_TRANSFERABILITY = 'CHECK_FARMER_TRANSFERABILITY',
  CHECK_FARMER_TRANSFERABILITY_ASYNC = 'CHECK_FARMER_TRANSFERABILITY_ASYNC',
  CHECK_FARMER_TRANSFERABILITY_SUCCESS = 'CHECK_FARMER_TRANSFERABILITY_SUCCESS',
  CHECK_FARMER_TRANSFERABILITY_FAIL = 'CHECK_FARMER_TRANSFERABILITY_FAIL',
  SUBMIT_WATANI_SURVEY = 'SUBMIT_WATANI_SURVEY',
  SUBMIT_WATANI_SURVEY_ASYNC = 'SUBMIT_WATANI_SURVEY_ASYNC',
  SUBMIT_WATANI_SURVEY_SUCCESS = 'SUBMIT_WATANI_SURVEY_SUCCESS',
  SUBMIT_WATANI_SURVEY_FAIL = 'SUBMIT_WATANI_SURVEY_FAIL',
  GET_FARMER_TRANSFER_REQUESTS_LIST = 'GET_FARMER_TRANSFER_REQUESTS_LIST',
  GET_FARMER_TRANSFER_REQUESTS_LIST_ASYNC = 'GET_FARMER_TRANSFER_REQUESTS_LIST_ASYNC',
  GET_FARMER_TRANSFER_REQUESTS_LIST_SUCCESS = 'GET_FARMER_TRANSFER_REQUESTS_LIST_SUCCESS',
  GET_FARMER_TRANSFER_REQUESTS_LIST_FAIL = 'GET_FARMER_TRANSFER_REQUESTS_LIST_FAIL',
  CANCEL_FARMER_TRANSFER_REQUEST = 'CANCEL_FARMER_TRANSFER_REQUEST',
  CANCEL_FARMER_TRANSFER_REQUEST_ASYNC = 'CANCEL_FARMER_TRANSFER_REQUEST_ASYNC',
  CANCEL_FARMER_TRANSFER_REQUEST_SUCCESS = 'CANCEL_FARMER_TRANSFER_REQUEST_SUCCESS',
  CANCEL_FARMER_TRANSFER_REQUEST_FAIL = 'CANCEL_FARMER_TRANSFER_REQUEST_FAIL',
  ACCEPT_FARMER_TRANSFER_REQUEST = 'ACCEPT_FARMER_TRANSFER_REQUEST',
  ACCEPT_FARMER_TRANSFER_REQUEST_ASYNC = 'ACCEPT_FARMER_TRANSFER_REQUEST_ASYNC',
  ACCEPT_FARMER_TRANSFER_REQUEST_SUCCESS = 'ACCEPT_FARMER_TRANSFER_REQUEST_SUCCESS',
  ACCEPT_FARMER_TRANSFER_REQUEST_FAIL = 'ACCEPT_FARMER_TRANSFER_REQUEST_FAIL',
  REJECT_FARMER_TRANSFER_REQUEST = 'REJECT_FARMER_TRANSFER_REQUEST',
  REJECT_FARMER_TRANSFER_REQUEST_ASYNC = 'REJECT_FARMER_TRANSFER_REQUEST_ASYNC',
  REJECT_FARMER_TRANSFER_REQUEST_SUCCESS = 'REJECT_FARMER_TRANSFER_REQUEST_SUCCESS',
  REJECT_FARMER_TRANSFER_REQUEST_FAIL = 'REJECT_FARMER_TRANSFER_REQUEST_FAIL',
  ISSUE_NEW_FARMER_TRANSFER = 'ISSUE_NEW_FARMER_TRANSFER',
  ISSUE_NEW_FARMER_TRANSFER_ASYNC = 'ISSUE_NEW_FARMER_TRANSFER_ASYNC',
  ISSUE_NEW_FARMER_TRANSFER_SUCCESS = 'ISSUE_NEW_FARMER_TRANSFER_SUCCESS',
  ISSUE_NEW_FARMER_TRANSFER_FAIL = 'ISSUE_NEW_FARMER_TRANSFER_FAIL',
  GET_CONTRACT_AUTH_EMLOYER_LABORERS = 'GET_CONTRACT_AUTH_EMLOYER_LABORERS',
  GET_CONTRACT_AUTH_EMLOYER_LABORERS_ASYNC = 'GET_CONTRACT_AUTH_EMLOYER_LABORERS_ASYNC',
  GET_CONTRACT_AUTH_EMLOYER_LABORERS_SUCCESS = 'GET_CONTRACT_AUTH_EMLOYER_LABORERS_SUCCESS',
  GET_CONTRACT_AUTH_EMLOYER_LABORERS_FAIL = 'GET_CONTRACT_AUTH_EMLOYER_LABORERS_FAIL',
  GET_REQUEST_ID_FOR_CONTRACT_ID = 'GET_REQUEST_ID_FOR_CONTRACT_ID',
  GET_REQUEST_ID_FOR_CONTRACT_ID_ASYNC = 'GET_REQUEST_ID_FOR_CONTRACT_ID_ASYNC',
  GET_REQUEST_ID_FOR_CONTRACT_ID_SUCCESS = 'GET_REQUEST_ID_FOR_CONTRACT_ID_SUCCESS',
  GET_REQUEST_ID_FOR_CONTRACT_ID_FAIL = 'GET_REQUEST_ID_FOR_CONTRACT_ID_FAIL',
  PAY_FOR_FARMER_TRANSFER = 'PAY_FOR_FARMER_TRANSFER',
  PAY_FOR_FARMER_TRANSFER_ASYNC = 'PAY_FOR_FARMER_TRANSFER_ASYNC',
  PAY_FOR_FARMER_TRANSFER_SUCCESS = 'PAY_FOR_FARMER_TRANSFER_SUCCESS',
  PAY_FOR_FARMER_TRANSFER_FAIL = 'PAY_FOR_FARMER_TRANSFER_FAIL',
  GET_PRO_SERVICE_DETAILS = 'GET_PRO_SERVICE_DETAILS',
  GET_PRO_SERVICE_DETAILS_ASYNC = 'GET_PRO_SERVICE_DETAILS_ASYNC',
  GET_PRO_SERVICE_DETAILS_SUCCESS = 'GET_PRO_SERVICE_DETAILS_SUCCESS',
  GET_PRO_SERVICE_DETAILS_FAIL = 'GET_PRO_SERVICE_DETAILS_FAIL',
  ADD_COMPLAINT_MISSING_ATTACHMENT = 'ADD_COMPLAINT_MISSING_ATTACHMENT',
  ADD_COMPLAINT_MISSING_ATTACHMENT_ASYNC = 'ADD_COMPLAINT_MISSING_ATTACHMENT_ASYNC',
  ADD_COMPLAINT_MISSING_ATTACHMENT_SUCCESS = 'ADD_COMPLAINT_MISSING_ATTACHMENT_SUCCESS',
  ADD_COMPLAINT_MISSING_ATTACHMENT_FAIL = 'ADD_COMPLAINT_MISSING_ATTACHMENT_FAIL',
  GET_WPS_LABORERS_LIST = 'GET_WPS_LABORERS_LIST',
  GET_WPS_LABORERS_LIST_ASYNC = 'GET_WPS_LABORERS_LIST_ASYNC',
  GET_WPS_LABORERS_LIST_SUCCESS = 'GET_WPS_LABORERS_LIST_SUCCESS',
  GET_WPS_LABORERS_LIST_FAIL = 'GET_WPS_LABORERS_LIST_FAIL',
  GET_WPS_CURRENT_LABORER = 'GET_WPS_CURRENT_LABORER',
  GET_WPS_CURRENT_LABORER_ASYNC = 'GET_WPS_CURRENT_LABORER_ASYNC',
  GET_WPS_CURRENT_LABORER_SUCCESS = 'GET_WPS_CURRENT_LABORER_SUCCESS',
  GET_WPS_CURRENT_LABORER_FAIL = 'GET_WPS_CURRENT_LABORER_FAIL',
  GET_WPS_LABORER_TRANSACTIONS = 'GET_WPS_LABORER_TRANSACTIONS',
  GET_WPS_LABORER_TRANSACTIONS_ASYNC = 'GET_WPS_LABORER_TRANSACTIONS_ASYNC',
  GET_WPS_LABORER_TRANSACTIONS_SUCCESS = 'GET_WPS_LABORER_TRANSACTIONS_SUCCESS',
  GET_WPS_LABORER_TRANSACTIONS_FAIL = 'GET_WPS_LABORER_TRANSACTIONS_FAIL',
  GET_WPS_CURRENT_TRANSACTION = 'GET_WPS_CURRENT_TRANSACTION',
  GET_WPS_CURRENT_TRANSACTION_ASYNC = 'GET_WPS_CURRENT_TRANSACTION_ASYNC',
  GET_WPS_CURRENT_TRANSACTION_SUCCESS = 'GET_WPS_CURRENT_TRANSACTION_SUCCESS',
  GET_WPS_CURRENT_TRANSACTION_FAIL = 'GET_WPS_CURRENT_TRANSACTION_FAIL',
  GET_I_TO_E_REQUESTS = 'GET_I_TO_E_REQUESTS',
  GET_I_TO_E_REQUESTS_ASYNC = 'GET_I_TO_E_REQUESTS_ASYNC',
  GET_I_TO_E_REQUESTS_SUCCESS = 'GET_I_TO_E_REQUESTS_SUCCESS',
  GET_I_TO_E_REQUESTS_FAIL = 'GET_I_TO_E_REQUESTS_FAIL',
  GET_I_TO_E_REQUEST_DETAILS = 'GET_I_TO_E_REQUEST_DETAILS',
  GET_I_TO_E_REQUEST_DETAILS_ASYNC = 'GET_I_TO_E_REQUEST_DETAILS_ASYNC',
  GET_I_TO_E_REQUEST_DETAILS_SUCCESS = 'GET_I_TO_E_REQUEST_DETAILS_SUCCESS',
  GET_I_TO_E_REQUEST_DETAILS_FAIL = 'GET_I_TO_E_REQUEST_DETAILS_FAIL',
  I_TO_E_REQUEST_ACTIONS = 'I_TO_E_REQUEST_ACTIONS',
  I_TO_E_REQUEST_ACTIONS_ASYNC = 'I_TO_E_REQUEST_ACTIONS_ASYNC',
  I_TO_E_REQUEST_ACTIONS_SUCCESS = 'I_TO_E_REQUEST_ACTIONS_SUCCESS',
  I_TO_E_REQUEST_ACTIONS_FAIL = 'I_TO_E_REQUEST_ACTIONS_FAIL',
  GET_WPS_COMMITMENTS_STATISTIC = 'GET_WPS_COMMITMENTS_STATISTIC',
  GET_WPS_COMMITMENTS_STATISTIC_ASYNC = 'GET_WPS_COMMITMENTS_STATISTIC_ASYNC',
  GET_WPS_COMMITMENTS_STATISTIC_SUCCESS = 'GET_WPS_COMMITMENTS_STATISTIC_SUCCESS',
  GET_WPS_COMMITMENTS_STATISTIC_FAIL = 'GET_WPS_COMMITMENTS_STATISTIC_FAIL',
  SET_CONTRACT_AUTH_REQUEST_DATA = 'SET_CONTRACT_AUTH_REQUEST_DATA',
  GET_NAJM_REDIRECTION_URL = 'GET_NAJM_REDIRECTION_URL',
  GET_NAJM_REDIRECTION_URL_ASYNC = 'GET_NAJM_REDIRECTION_URL_ASYNC',
  GET_NAJM_REDIRECTION_URL_SUCCESS = 'GET_NAJM_REDIRECTION_URL_SUCCESS',
  GET_NAJM_REDIRECTION_URL_FAIL = 'GET_NAJM_REDIRECTION_URL_FAIL',
  CHECK_CAPTCHA = 'CHECK_CAPTCHA',
  CHECK_CAPTCHA_ASYNC = 'CHECK_CAPTCHA_ASYNC',
  CHECK_CAPTCHA_SUCCESS = 'CHECK_CAPTCHA_SUCCESS',
  CHECK_CAPTCHA_FAIL = 'CHECK_CAPTCHA_FAIL',
  GET_REFUND_DETAILS = 'GET_REFUND_DETAILS',
  GET_REFUND_DETAILS_ASYNC = 'GET_REFUND_DETAILS_ASYNC',
  GET_REFUND_DETAILS_SUCCESS = 'GET_REFUND_DETAILS_SUCCESS',
  GET_REFUND_DETAILS_FAIL = 'GET_REFUND_DETAILS_FAIL',
  DO_NOTHING = 'DO_NOTHING', // TODO should be removed after functionality deployed
  REJECT_CONTRACT = 'REJECT_CONTRACT',
  REJECT_CONTRACT_ASYNC = 'REJECT_CONTRACT_ASYNC',
  REJECT_CONTRACT_SUCCESS = 'REJECT_CONTRACT_SUCCESS',
  REJECT_CONTRACT_FAIL = 'REJECT_CONTRACT_FAIL',
  ACCEPT_CONTRACT = 'ACCEPT_CONTRACT',
  ACCEPT_CONTRACT_ASYNC = 'ACCEPT_CONTRACT_ASYNC',
  ACCEPT_CONTRACT_SUCCESS = 'ACCEPT_CONTRACT_SUCCESS',
  ACCEPT_CONTRACT_FAIL = 'ACCEPT_CONTRACT_FAIL',
  GET_CONTRACT_INFO = 'GET_CONTRACT_INFO',
  GET_CONTRACT_INFO_ASYNC = 'GET_CONTRACT_INFO_ASYNC',
  GET_CONTRACT_INFO_SUCCESS = 'GET_CONTRACT_INFO_SUCCESS',
  GET_CONTRACT_INFO_FAIL = 'GET_CONTRACT_INFO_FAIL',
  SET_CANCELLATION_CONTRACT_REQUEST_DATA = 'SET_CANCELLATION_CONTRACT_REQUEST_DATA',
  LABORER_LOGIN = 'LABORER_LOGIN',
  LABORER_LOGIN_ASYNC = 'LABORER_LOGIN_ASYNC',
  LABORER_LOGIN_SUCCESS = 'LABORER_LOGIN_SUCCESS',
  LABORER_LOGIN_FAIL = 'LABORER_LOGIN_FAIL',
  REFRESH_LABORER_TOKEN = 'REFRESH_LABORER_TOKEN',
  REFRESH_LABORER_TOKEN_ASYNC = 'REFRESH_LABORER_TOKEN_ASYNC',
  REFRESH_LABORER_TOKEN_SUCCESS = 'REFRESH_LABORER_TOKEN_SUCCESS',
  REFRESH_LABORER_TOKEN_FAIL = 'REFRESH_LABORER_TOKEN_FAIL',
  UPDATE_LABORER_AUTH_DATA = 'UPDATE_LABORER_AUTH_DATA',
  LABORER_OTP_VERIFY = 'LABORER_OTP_VERIFY',
  LABORER_OTP_VERIFY_ASYNC = 'LABORER_OTP_VERIFY_ASYNC',
  LABORER_OTP_VERIFY_SUCCESS = 'LABORER_OTP_VERIFY_SUCCESS',
  LABORER_OTP_VERIFY_FAIL = 'LABORER_OTP_VERIFY_FAIL',
  GET_I_TO_I_REQUESTS_LIST = 'GET_I_TO_I_REQUESTS_LIST',
  GET_I_TO_I_REQUESTS_LIST_ASYNC = 'GET_I_TO_I_REQUESTS_LIST_ASYNC',
  GET_I_TO_I_REQUESTS_LIST_SUCCESS = 'GET_I_TO_I_REQUESTS_LIST_SUCCESS',
  GET_I_TO_I_REQUESTS_LIST_FAIL = 'GET_I_TO_I_REQUESTS_FAIL',
  GET_I_TO_I_LABORER_LIST = 'GET_I_TO_I_LABORER_LIST',
  GET_I_TO_I_LABORER_LIST_ASYNC = 'GET_I_TO_I_LABORER_LIST_ASYNC',
  GET_I_TO_I_LABORER_LIST_SUCCESS = 'GET_I_TO_I_LABORER_LIST_SUCCESS',
  GET_I_TO_I_LABORER_LIST_FAIL = 'GET_I_TO_I_LABORER_LIST_FAIL',
  GET_LABORER_TRANSFERS_LIST = 'GET_LABORER_TRANSFERS_LIST',
  GET_LABORER_TRANSFERS_LIST_ASYNC = 'GET_LABORER_TRANSFERS_LIST_ASYNC',
  GET_LABORER_TRANSFERS_LIST_SUCCESS = 'GET_LABORER_TRANSFERS_LIST_SUCCESS',
  GET_LABORER_TRANSFERS_LIST_FAIL = 'GET_LABORER_TRANSFERS_LIST_FAIL',
  GET_LABORER_AUTH_REQUEST_LIST = 'GET_LABORER_AUTH_REQUEST_LIST',
  GET_LABORER_AUTH_REQUEST_LIST_ASYNC = 'GET_LABORER_AUTH_REQUEST_LIST_ASYNC',
  GET_LABORER_AUTH_REQUEST_LIST_SUCCESS = 'GET_LABORER_AUTH_REQUEST_LIST_SUCCESS',
  GET_LABORER_AUTH_REQUEST_LIST_FAIL = 'GET_LABORER_AUTH_REQUEST_LIST_FAIL',
  GET_LABORER_AUTH_REQUEST_DETAILS = 'GET_LABORER_AUTH_REQUEST_DETAILS',
  GET_LABORER_AUTH_REQUEST_DETAILS_ASYNC = 'GET_LABORER_AUTH_REQUEST_DETAILS_ASYNC',
  GET_LABORER_AUTH_REQUEST_DETAILS_SUCCESS = 'GET_LABORER_AUTH_REQUEST_DETAILS_SUCCESS',
  GET_LABORER_AUTH_REQUEST_DETAILS_FAIL = 'GET_LABORER_AUTH_REQUEST_DETAILS_FAIL',
  GET_LABORER_AUTH_CONTRACT_TEXT = 'GET_LABORER_AUTH_CONTRACT_TEXT',
  GET_LABORER_AUTH_CONTRACT_TEXT_ASYNC = 'GET_LABORER_AUTH_CONTRACT_TEXT_ASYNC',
  GET_LABORER_AUTH_CONTRACT_TEXT_SUCCESS = 'GET_LABORER_AUTH_CONTRACT_TEXT_SUCCESS',
  GET_LABORER_AUTH_CONTRACT_TEXT_FAIL = 'GET_LABORER_AUTH_CONTRACT_TEXT_FAIL',
  ACCEPT_LABORER_AUTH_REQUEST = 'ACCEPT_LABORER_AUTH_REQUEST',
  ACCEPT_LABORER_AUTH_REQUEST_ASYNC = 'ACCEPT_LABORER_AUTH_REQUEST_ASYNC',
  ACCEPT_LABORER_AUTH_REQUEST_SUCCESS = 'ACCEPT_LABORER_AUTH_REQUEST_SUCCESS',
  ACCEPT_LABORER_AUTH_REQUEST_FAIL = 'ACCEPT_LABORER_AUTH_REQUEST_FAIL',
  REJECT_LABORER_AUTH_REQUEST = 'REJECT_LABORER_AUTH_REQUEST',
  REJECT_LABORER_AUTH_REQUEST_ASYNC = 'REJECT_LABORER_AUTH_REQUEST_ASYNC',
  REJECT_LABORER_AUTH_REQUEST_SUCCESS = 'REJECT_LABORER_AUTH_REQUEST_SUCCESS',
  REJECT_LABORER_AUTH_REQUEST_FAIL = 'REJECT_LABORER_AUTH_REQUEST_FAIL',
  GET_AUTH_REJECTION_REASONS_LIST = 'GET_AUTH_REJECTION_REASONS_LIST',
  GET_AUTH_REJECTION_REASONS_LIST_ASYNC = 'GET_AUTH_REJECTION_REASONS_LIST_ASYNC',
  GET_AUTH_REJECTION_REASONS_LIST_SUCCESS = 'GET_AUTH_REJECTION_REASONS_LIST_SUCCESS',
  GET_AUTH_REJECTION_REASONS_LIST_FAIL = 'GET_AUTH_REJECTION_REASONS_LIST_FAIL',
  GET_LABORER_TRANSFER_DETAILS = 'GET_LABORER_TRANSFER_DETAILS',
  GET_LABORER_TRANSFER_DETAILS_ASYNC = 'GET_LABORER_TRANSFER_DETAILS_ASYNC',
  GET_LABORER_TRANSFER_DETAILS_SUCCESS = 'GET_LABORER_TRANSFER_DETAILS_SUCCESS',
  GET_LABORER_TRANSFER_DETAILS_FAIL = 'GET_LABORER_TRANSFER_DETAILS_FAIL',
  GET_LABORER_AUTH_APPENDIX_LIST = 'GET_LABORER_AUTH_APPENDIX_LIST',
  GET_LABORER_AUTH_APPENDIX_LIST_ASYNC = 'GET_LABORER_AUTH_APPENDIX_LIST_ASYNC',
  GET_LABORER_AUTH_APPENDIX_LIST_SUCCESS = 'GET_LABORER_AUTH_APPENDIX_LIST_SUCCESS',
  GET_LABORER_AUTH_APPENDIX_LIST_FAIL = 'GET_LABORER_AUTH_APPENDIX_LIST_FAIL',
  GET_LABORER_AUTH_APPENDIX_DETAILS = 'GET_LABORER_AUTH_APPENDIX_DETAILS',
  GET_LABORER_AUTH_APPENDIX_DETAILS_ASYNC = 'GET_LABORER_AUTH_APPENDIX_DETAILS_ASYNC',
  GET_LABORER_AUTH_APPENDIX_DETAILS_SUCCESS = 'GET_LABORER_AUTH_APPENDIX_DETAILS_SUCCESS',
  GET_LABORER_AUTH_APPENDIX_DETAILS_FAIL = 'GET_LABORER_AUTH_APPENDIX_DETAILS_FAIL',
  REJECT_LABORER_AUTH_APPENDIX = 'REJECT_LABORER_AUTH_APPENDIX',
  REJECT_LABORER_AUTH_APPENDIX_ASYNC = 'REJECT_LABORER_AUTH_APPENDIX_ASYNC',
  REJECT_LABORER_AUTH_APPENDIX_SUCCESS = 'REJECT_LABORER_AUTH_APPENDIX_SUCCESS',
  REJECT_LABORER_AUTH_APPENDIX_FAIL = 'REJECT_LABORER_AUTH_APPENDIX_FAIL',
  APPROVE_LABORER_AUTH_APPENDIX = 'APPROVE_LABORER_AUTH_APPENDIX',
  APPROVE_LABORER_AUTH_APPENDIX_ASYNC = 'APPROVE_LABORER_AUTH_APPENDIX_ASYNC',
  APPROVE_LABORER_AUTH_APPENDIX_SUCCESS = 'APPROVE_LABORER_AUTH_APPENDIX_SUCCESS',
  APPROVE_LABORER_AUTH_APPENDIX_FAIL = 'APPROVE_LABORER_AUTH_APPENDIX_FAIL',
  REJECT_LABORER_TRANSFER_REQUEST = 'REJECT_LABORER_TRANSFER_REQUEST',
  REJECT_LABORER_TRANSFER_REQUEST_ASYNC = 'REJECT_LABORER_TRANSFER_REQUEST_ASYNC',
  REJECT_LABORER_TRANSFER_REQUEST_SUCCESS = 'REJECT_LABORER_TRANSFER_REQUEST_SUCCESS',
  REJECT_LABORER_TRANSFER_REQUEST_FAIL = 'REJECT_LABORER_TRANSFER_REQUEST_FAIL',
  ACCEPT_LABORER_TRANSFER_REQUEST = 'ACCEPT_LABORER_TRANSFER_REQUEST',
  ACCEPT_LABORER_TRANSFER_REQUEST_ASYNC = 'ACCEPT_LABORER_TRANSFER_REQUEST_ASYNC',
  ACCEPT_LABORER_TRANSFER_REQUEST_SUCCESS = 'ACCEPT_LABORER_TRANSFER_REQUEST_SUCCESS',
  ACCEPT_LABORER_TRANSFER_REQUEST_FAIL = 'ACCEPT_LABORER_TRANSFER_REQUEST_FAIL',
  SET_RECRUITMENT_FILTER = 'SET_RECRUITMENT_FILTER',
  SUBMIT_SURVEY = 'SUBMIT_SURVEY',
  SUBMIT_SURVEY_ASYNC = 'SUBMIT_SURVEY_ASYNC',
  SUBMIT_SURVEY_SUCCESS = 'SUBMIT_SURVEY_SUCCESS',
  SUBMIT_SURVEY_FAIL = 'SUBMIT_SURVEY_FAIL',
  REJECT_I_TO_I_OFFER = 'REJECT_I_TO_I_OFFER',
  REJECT_I_TO_I_OFFER_ASYNC = 'REJECT_I_TO_I_OFFER_ASYNC',
  REJECT_I_TO_I_OFFER_SUCCESS = 'REJECT_I_TO_I_OFFER_SUCCESS',
  REJECT_I_TO_I_OFFER_FAIL = 'REJECT_I_TO_I_OFFER_FAIL',
  ACCEPT_I_TO_I_OFFER = 'ACCEPT_I_TO_I_OFFER',
  ACCEPT_I_TO_I_OFFER_ASYNC = 'ACCEPT_I_TO_I_OFFER_ASYNC',
  ACCEPT_I_TO_I_OFFER_SUCCESS = 'ACCEPT_I_TO_I_OFFER_SUCCESS',
  ACCEPT_I_TO_I_OFFER_FAIL = 'ACCEPT_I_TO_I_OFFER_FAIL',
  GET_I_TO_I_REQUEST_DETAILS = 'GET_I_TO_I_REQUEST_DETAILS',
  GET_I_TO_I_REQUEST_DETAILS_ASYNC = 'GET_I_TO_I_REQUEST_DETAILS_ASYNC',
  GET_I_TO_I_REQUEST_DETAILS_SUCCESS = 'GET_I_TO_I_REQUEST_DETAILS_SUCCESS',
  GET_I_TO_I_REQUEST_DETAILS_FAIL = 'GET_I_TO_I_REQUEST_DETAILS_FAIL',
  GET_I_TO_I_LABORER_DETAILS = 'GET_I_TO_I_LABORER_DETAILS',
  GET_I_TO_I_LABORER_DETAILS_ASYNC = 'GET_I_TO_I_LABORER_DETAILS_ASYNC',
  GET_I_TO_I_LABORER_DETAILS_SUCCESS = 'GET_I_TO_I_LABORER_DETAILS_SUCCESS',
  GET_I_TO_I_LABORER_DETAILS_FAIL = 'GET_I_TO_I_LABORER_DETAILS_FAIL',
  ADD_I_TO_I_FINANCIAL_DOCUMENTS = 'ADD_I_TO_I_FINANCIAL_DOCUMENTS',
  ADD_I_TO_I_FINANCIAL_DOCUMENTS_ASYNC = 'ADD_I_TO_I_FINANCIAL_DOCUMENTS_ASYNC',
  ADD_I_TO_I_FINANCIAL_DOCUMENTS_SUCCESS = 'ADD_I_TO_I_FINANCIAL_DOCUMENTS_SUCCESS',
  ADD_I_TO_I_FINANCIAL_DOCUMENTS_FAIL = 'ADD_I_TO_I_FINANCIAL_DOCUMENTS_FAIL',
  PAY_FOR_I_TO_I_REQUEST = 'PAY_FOR_I_TO_I_REQUEST',
  PAY_FOR_I_TO_I_REQUEST_ASYNC = 'PAY_FOR_I_TO_I_REQUEST_ASYNC',
  PAY_FOR_I_TO_I_REQUEST_SUCCESS = 'PAY_FOR_I_TO_I_REQUEST_SUCCESS',
  PAY_FOR_I_TO_I_REQUEST_FAIL = 'PAY_FOR_I_TO_I_REQUEST_FAIL',
  ADD_TO_PILOT_LIST = 'ADD_TO_PILOT_LIST',
  ADD_TO_PILOT_LIST_ASYNC = 'ADD_TO_PILOT_LIST_ASYNC',
  ADD_TO_PILOT_LIST_SUCCESS = 'ADD_TO_PILOT_LIST_SUCCESS',
  ADD_TO_PILOT_LIST_FAIL = 'ADD_TO_PILOT_LIST_FAIL',
  REMOVE_FROM_PILOT_LIST = 'REMOVE_FROM_PILOT_LIST',
  REMOVE_FROM_PILOT_LIST_ASYNC = 'REMOVE_FROM_PILOT_LIST_ASYNC',
  REMOVE_FROM_PILOT_LIST_SUCCESS = 'REMOVE_FROM_PILOT_LIST_SUCCESS',
  REMOVE_FROM_PILOT_LIST_FAIL = 'REMOVE_FROM_PILOT_LIST_FAIL',
  GET_I_TO_I_BILL = 'GET_I_TO_I_BILL',
  GET_I_TO_I_BILL_ASYNC = 'GET_I_TO_I_BILL_ASYNC',
  GET_I_TO_I_BILL_SUCCESS = 'GET_I_TO_I_BILL_SUCCESS',
  GET_I_TO_I_BILL_FAIL = 'GET_I_TO_I_BILL_FAIL',
  REPORT_RUNAWAY_LABORER = 'REPORT_RUNAWAY_LABORER',
  REPORT_RUNAWAY_LABORER_ASYNC = 'REPORT_RUNAWAY_LABORER_ASYNC',
  REPORT_RUNAWAY_LABORER_SUCCESS = 'REPORT_RUNAWAY_LABORER_SUCCESS',
  REPORT_RUNAWAY_LABORER_FAIL = 'REPORT_RUNAWAY_LABORER_FAIL',
  CREATE_SUPPORT_TICKET_GUEST = 'CREATE_SUPPORT_TICKET_GUEST',
  CREATE_SUPPORT_TICKET_GUEST_ASYNC = 'CREATE_SUPPORT_TICKET_GUEST_ASYNC',
  CREATE_SUPPORT_TICKET_GUEST_SUCCESS = 'CREATE_SUPPORT_TICKET_GUEST_SUCCESS',
  CREATE_SUPPORT_TICKET_GUEST_FAIL = 'CREATE_SUPPORT_TICKET_GUEST_FAIL',
  OPEN_AUTH_BANNER = 'OPEN_AUTH_BANNER',
  CLOSE_AUTH_BANNER = 'CLOSE_AUTH_BANNER',
  CHECK_LABORERS_FOR_AUTH_BANNER = 'CHECK_LABORERS_FOR_AUTH_BANNER',
  SET_SUPPORT_TICKET_DATA = 'SET_SUPPORT_TICKET_DATA',
  GET_LAST_FOUR_VISAS = 'GET_LAST_FOUR_VISAS',
  GET_LAST_FOUR_VISAS_ASYNC = 'GET_LAST_FOUR_VISAS_ASYNC',
  GET_LAST_FOUR_VISAS_SUCCESS = 'GET_LAST_FOUR_VISAS_SUCCESS',
  GET_LAST_FOUR_VISAS_FAIL = 'GET_LAST_FOUR_VISAS_FAIL',
  CREATE_REFUND_TICKET = 'CREATE_REFUND_TICKET',
  CREATE_REFUND_TICKET_ASYNC = 'CREATE_REFUND_TICKET_ASYNC',
  CREATE_REFUND_TICKET_SUCCESS = 'CREATE_REFUND_TICKET_SUCCESS',
  CREATE_REFUND_TICKET_FAIL = 'CREATE_REFUND_TICKET_FAIL',
  CANCEL_REPORT_RUNAWAY_LABORER = 'CANCEL_REPORT_RUNAWAY_LABORER',
  CANCEL_REPORT_RUNAWAY_LABORER_ASYNC = 'CANCEL_REPORT_RUNAWAY_LABORER_ASYNC',
  CANCEL_REPORT_RUNAWAY_LABORER_SUCCESS = 'CANCEL_REPORT_RUNAWAY_LABORER_SUCCESS',
  CANCEL_REPORT_RUNAWAY_LABORER_FAIL = 'CANCEL_REPORT_RUNAWAY_LABORER_FAIL',
  GET_ELIGIBLE_LABORERS = 'GET_ELIGIBLE_LABORERS',
  GET_ELIGIBLE_LABORERS_ASYNC = 'GET_ELIGIBLE_LABORERS_ASYNC',
  GET_ELIGIBLE_LABORERS_SUCCESS = 'GET_ELIGIBLE_LABORERS_SUCCESS',
  GET_ELIGIBLE_LABORERS_FAIL = 'GET_ELIGIBLE_LABORERS_FAIL',
  CHECK_FEES_EXEMPTION = 'CHECK_FEES_EXEMPTION',
  CHECK_FEES_EXEMPTION_ASYNC = 'CHECK_FEES_EXEMPTION_ASYNC',
  CHECK_FEES_EXEMPTION_SUCCESS = 'CHECK_FEES_EXEMPTION_SUCCESS',
  CHECK_FEES_EXEMPTION_FAIL = 'CHECK_FEES_EXEMPTION_FAIL',
  SUBMIT_FEES_EXEMPTION = 'SUBMIT_FEES_EXEMPTION',
  SUBMIT_FEES_EXEMPTION_ASYNC = 'SUBMIT_FEES_EXEMPTION_ASYNC',
  SUBMIT_FEES_EXEMPTION_SUCCESS = 'SUBMIT_FEES_EXEMPTION_SUCCESS',
  SUBMIT_FEES_EXEMPTION_FAIL = 'SUBMIT_FEES_EXEMPTION_FAIL',
  UPLOAD_DISABILITY_PROOF = 'UPLOAD_DISABILITY_PROOF',
  UPLOAD_DISABILITY_PROOF_ASYNC = 'UPLOAD_DISABILITY_PROOF_ASYNC',
  UPLOAD_DISABILITY_PROOF_SUCCESS = 'UPLOAD_DISABILITY_PROOF_SUCCESS',
  UPLOAD_DISABILITY_PROOF_FAIL = 'UPLOAD_DISABILITY_PROOF_FAIL',
  REMOVE_DISABILITY_PROOF_FILE = 'REMOVE_DISABILITY_PROOF_FILE',
  UPDATE_FILES_PROGRESS = 'UPDATE_FILES_PROGRESS',
  GET_RUNAWAYS = 'GET_RUNAWAYS',
  GET_RUNAWAYS_ASYNC = 'GET_RUNAWAYS_ASYNC',
  GET_RUNAWAYS_SUCCESS = 'GET_RUNAWAYS_SUCCESS',
  GET_RUNAWAYS_FAIL = 'GET_RUNAWAYS_FAIL',
  COMPLAINT_INQUIRY = 'COMPLAINT_INQUIRY',
  COMPLAINT_INQUIRY_ASYNC = 'COMPLAINT_INQUIRY_ASYNC',
  COMPLAINT_INQUIRY_SUCCESS = 'COMPLAINT_INQUIRY_SUCCESS',
  COMPLAINT_INQUIRY_FAIL = 'COMPLAINT_INQUIRY_FAIL',
  RESEND_E_TO_I_REQUEST = 'RESEND_E_TO_I_REQUEST',
  RESEND_E_TO_I_REQUEST_ASYNC = 'RESEND_E_TO_I_REQUEST_ASYNC',
  RESEND_E_TO_I_REQUEST_SUCCESS = 'RESEND_E_TO_I_REQUEST_SUCCESS',
  RESEND_E_TO_I_REQUEST_FAIL = 'RESEND_E_TO_I_REQUEST_FAIL',
  SET_LABORER_I_TO_I_RELATIVE_INFO = 'SET_LABORER_I_TO_I_RELATIVE_INFO',
  GET_I_TO_I_SUGGESTED_COST = 'GET_I_TO_I_SUGGESTED_COST',
  GET_I_TO_I_SUGGESTED_COST_ASYNC = 'GET_I_TO_I_SUGGESTED_COST_ASYNC',
  GET_I_TO_I_SUGGESTED_COST_SUCCESS = 'GET_I_TO_I_SUGGESTED_COST_SUCCESS',
  GET_I_TO_I_SUGGESTED_COST_FAIL = 'GET_I_TO_I_SUGGESTED_COST_FAIL',
  SEND_LABORER_RELATIVE_INFO = 'SEND_LABORER_RELATIVE_INFO',
  SEND_LABORER_RELATIVE_INFO_ASYNC = 'SEND_LABORER_RELATIVE_INFO_ASYNC',
  SEND_LABORER_RELATIVE_INFO_SUCCESS = 'SEND_LABORER_RELATIVE_INFO_SUCCESS',
  SEND_LABORER_RELATIVE_INFO_FAIL = 'SEND_LABORER_RELATIVE_INFO_FAIL',
  CREATE_CONTRACT_AUTH_APPENDIX = 'CREATE_CONTRACT_AUTH_APPENDIX',
  CREATE_CONTRACT_AUTH_APPENDIX_ASYNC = 'CREATE_CONTRACT_AUTH_APPENDIX_ASYNC',
  CREATE_CONTRACT_AUTH_APPENDIX_SUCCESS = 'CREATE_CONTRACT_AUTH_APPENDIX_SUCCESS',
  CREATE_CONTRACT_AUTH_APPENDIX_FAIL = 'CREATE_CONTRACT_AUTH_APPENDIX_FAIL',
  SET_AUTH_CONTRACT_APPENDIX_DATA = 'SET_AUTH_CONTRACT_APPENDIX_DATA',
  UPDATE_NATIONAL_ADDRESS = 'UPDATE_NATIONAL_ADDRESS',
  UPDATE_NATIONAL_ADDRESS_ASYNC = 'UPDATE_NATIONAL_ADDRESS_ASYNC',
  UPDATE_NATIONAL_ADDRESS_SUCCESS = 'UPDATE_NATIONAL_ADDRESS_SUCCESS',
  UPDATE_NATIONAL_ADDRESS_FAIL = 'UPDATE_NATIONAL_ADDRESS_FAIL',
  SET_NEW_PRIMARY_ADDRESS = 'SET_NEW_PRIMARY_ADDRESS',
  SET_NEW_PRIMARY_ADDRESS_ASYNC = 'SET_NEW_PRIMARY_ADDRESS_ASYNC',
  SET_NEW_PRIMARY_ADDRESS_SUCCESS = 'SET_NEW_PRIMARY_ADDRESS_SUCCESS',
  SET_NEW_PRIMARY_ADDRESS_FAIL = 'SET_NEW_PRIMARY_ADDRESS_FAIL',
  GET_I_TO_I_INVOICE_FILE = 'GET_I_TO_I_INVOICE_FILE',
  GET_I_TO_I_INVOICE_FILE_ASYNC = 'GET_I_TO_I_INVOICE_FILE_ASYNC',
  GET_I_TO_I_INVOICE_FILE_SUCCESS = 'GET_I_TO_I_INVOICE_FILE_SUCCESS',
  GET_I_TO_I_INVOICE_FILE_FAIL = 'GET_I_TO_I_INVOICE_FILE_FAIL',
  UPDATE_LABORER_MOBILE_NUMBER = 'UPDATE_LABORER_MOBILE_NUMBER',
  UPDATE_LABORER_MOBILE_NUMBER_ASYNC = 'UPDATE_LABORER_MOBILE_NUMBER_ASYNC',
  UPDATE_LABORER_MOBILE_NUMBER_SUCCESS = 'UPDATE_LABORER_MOBILE_NUMBER_SUCCESS',
  UPDATE_LABORER_MOBILE_NUMBER_FAIL = 'UPDATE_LABORER_MOBILE_NUMBER_FAIL',
  GET_E_TO_I_INVOICE_FILE = 'GET_E_TO_I_INVOICE_FILE',
  GET_E_TO_I_INVOICE_FILE_ASYNC = 'GET_E_TO_I_INVOICE_FILE_ASYNC',
  GET_E_TO_I_INVOICE_FILE_SUCCESS = 'GET_E_TO_I_INVOICE_FILE_SUCCESS',
  GET_E_TO_I_INVOICE_FILE_FAIL = 'GET_E_TO_I_INVOICE_FILE_FAIL',
  REMOVE_AGENT = 'REMOVE_AGENT',
  REMOVE_AGENT_ASYNC = 'REMOVE_AGENT_ASYNC',
  REMOVE_AGENT_SUCCESS = 'REMOVE_AGENT_SUCCESS',
  REMOVE_AGENT_FAIL = 'REMOVE_AGENT_FAIL',
  VERIFY_AGENT = 'VERIFY_AGENT',
  VERIFY_AGENT_ASYNC = 'VERIFY_AGENT_ASYNC',
  VERIFY_AGENT_SUCCESS = 'VERIFY_AGENT_SUCCESS',
  VERIFY_AGENT_FAIL = 'VERIFY_AGENT_FAIL',
  CREATE_AGENT = 'CREATE_AGENT',
  CREATE_AGENT_ASYNC = 'CREATE_AGENT_ASYNC',
  CREATE_AGENT_SUCCESS = 'CREATE_AGENT_SUCCESS',
  CREATE_AGENT_FAIL = 'CREATE_AGENT_FAIL',
  GET_VISA_PDF_URL = 'GET_VISA_PDF_URL',
  GET_VISA_PDF_URL_ASYNC = 'GET_VISA_PDF_URL_ASYNC',
  GET_VISA_PDF_URL_SUCCESS = 'GET_VISA_PDF_URL_SUCCESS',
  GET_VISA_PDF_URL_FAIL = 'GET_VISA_PDF_URL_FAIL',
  CANCEL_CONTRACT_AUTH_APPENDIX = 'CANCEL_CONTRACT_AUTH_APPENDIX',
  CANCEL_CONTRACT_AUTH_APPENDIX_ASYNC = 'CANCEL_CONTRACT_AUTH_APPENDIX_ASYNC',
  CANCEL_CONTRACT_AUTH_APPENDIX_SUCCESS = 'CANCEL_CONTRACT_AUTH_APPENDIX_SUCCESS',
  CANCEL_CONTRACT_AUTH_APPENDIX_FAIL = 'CANCEL_CONTRACT_AUTH_APPENDIX_FAIL',
  GET_PRO_SERVICE_ADDONS = 'GET_PRO_SERVICE_ADDONS',
  GET_PRO_SERVICE_ADDONS_ASYNC = 'GET_PRO_SERVICE_ADDONS_ASYNC',
  GET_PRO_SERVICE_ADDONS_SUCCESS = 'GET_PRO_SERVICE_ADDONS_SUCCESS',
  GET_PRO_SERVICE_ADDONS_FAIL = 'GET_PRO_SERVICE_ADDONS_FAIL',
  GET_PRO_SERVICE_ADDONS_ALL = 'GET_PRO_SERVICE_ADDONS_ALL',
  GET_PRO_SERVICE_ADDONS_ALL_ASYNC = 'GET_PRO_SERVICE_ADDONS_ALL_ASYNC',
  GET_PRO_SERVICE_ADDONS_ALL_SUCCESS = 'GET_PRO_SERVICE_ADDONS_ALL_SUCCESS',
  GET_PRO_SERVICE_ADDONS_ALL_FAIL = 'GET_PRO_SERVICE_ADDONS_ALL_FAIL',
  GET_PRO_RECRUITED_SERVICE_ADDONS = 'GET_PRO_RECRUITED_SERVICE_ADDONS',
  GET_PRO_RECRUITED_SERVICE_ADDONS_ASYNC = 'GET_PRO_RECRUITED_SERVICE_ADDONS_ASYNC',
  GET_PRO_RECRUITED_SERVICE_ADDONS_SUCCESS = 'GET_PRO_RECRUITED_SERVICE_ADDONS_SUCCESS',
  GET_PRO_RECRUITED_SERVICE_ADDONS_FAIL = 'GET_PRO_RECRUITED_SERVICE_ADDONS_FAIL',
  GET_CONTRACT_INVOICE_FILE = 'GET_CONTRACT_INVOICE_FILE',
  GET_CONTRACT_INVOICE_FILE_ASYNC = 'GET_CONTRACT_INVOICE_FILE_ASYNC',
  GET_CONTRACT_INVOICE_FILE_SUCCESS = 'GET_CONTRACT_INVOICE_FILE_SUCCESS',
  GET_CONTRACT_INVOICE_FILE_FAIL = 'GET_CONTRACT_INVOICE_FILE_FAIL',
  GET_PRO_PERFORMANCE = 'GET_PRO_PERFORMANCE',
  GET_PRO_PERFORMANCE_ASYNC = 'GET_PRO_PERFORMANCE_ASYNC',
  GET_PRO_PERFORMANCE_SUCCESS = 'GET_PRO_PERFORMANCE_SUCCESS',
  GET_PRO_PERFORMANCE_FAIL = 'GET_PRO_PERFORMANCE_FAIL',
  GET_PRO_PERFORMANCE_OVERALL = 'GET_PRO_PERFORMANCE_OVERALL',
  GET_PRO_PERFORMANCE_OVERALL_ASYNC = 'GET_PRO_PERFORMANCE_OVERALL_ASYNC',
  GET_PRO_PERFORMANCE_OVERALL_SUCCESS = 'GET_PRO_PERFORMANCE_OVERALL_SUCCESS',
  GET_PRO_PERFORMANCE_OVERALL_FAIL = 'GET_PRO_PERFORMANCE_OVERALL_FAIL',
  GET_ACCEPTANCE_RATE = 'GET_ACCEPTANCE_RATE',
  GET_ACCEPTANCE_RATE_ASYNC = 'GET_ACCEPTANCE_RATE_ASYNC',
  GET_ACCEPTANCE_RATE_SUCCESS = 'GET_ACCEPTANCE_RATE_SUCCESS',
  GET_ACCEPTANCE_RATE_FAIL = 'GET_ACCEPTANCE_RATE_FAIL',
  SET_PRO_PERFORMANCE = 'SET_PRO_PERFORMANCE',
  SET_VIP_RECRUITMENT_FILTER = 'SET_VIP_RECRUITMENT_FILTER',
  SET_VIP_PRESELECTED_INFO = 'SET_VIP_PRESELECTED_INFO',
  ADD_SELECTED_VIP_OFFER = 'ADD_SELECTED_VIP_OFFER',
  REMOVE_SELECTED_VIP_OFFER = 'REMOVE_SELECTED_VIP_OFFER',
  GET_LABORER_AUTH_CONTRACT_PDF = 'GET_LABORER_AUTH_CONTRACT_PDF',
  GET_LABORER_AUTH_CONTRACT_PDF_ASYNC = 'GET_LABORER_AUTH_CONTRACT_PDF_ASYNC',
  GET_LABORER_AUTH_CONTRACT_PDF_SUCCESS = 'GET_LABORER_AUTH_CONTRACT_PDF_SUCCESS',
  GET_LABORER_AUTH_CONTRACT_PDF_FAIL = 'GET_LABORER_AUTH_CONTRACT_PDF_FAIL',
  GET_AUTH_CONTRACT_PDF = 'GET_AUTH_CONTRACT_PDF',
  GET_AUTH_CONTRACT_PDF_ASYNC = 'GET_AUTH_CONTRACT_PDF_ASYNC',
  GET_AUTH_CONTRACT_PDF_SUCCESS = 'GET_AUTH_CONTRACT_PDF_SUCCESS',
  GET_AUTH_CONTRACT_PDF_FAIL = 'GET_AUTH_CONTRACT_PDF_FAIL',
  GET_AUTH_CONTRACT = 'GET_AUTH_CONTRACT',
  GET_AUTH_CONTRACT_ASYNC = 'GET_AUTH_CONTRACT_ASYNC',
  GET_AUTH_CONTRACT_SUCCESS = 'GET_AUTH_CONTRACT_SUCCESS',
  GET_AUTH_CONTRACT_FAIL = 'GET_AUTH_CONTRACT_FAIL',
  GET_SIMPLE_AUTH_CONTRACT_INFO = 'GET_SIMPLE_AUTH_CONTRACT_INFO',
  GET_SIMPLE_AUTH_CONTRACT_INFO_ASYNC = 'GET_SIMPLE_AUTH_CONTRACT_INFO_ASYNC',
  GET_SIMPLE_AUTH_CONTRACT_INFO_SUCCESS = 'GET_SIMPLE_AUTH_CONTRACT_INFO_SUCCESS',
  GET_SIMPLE_AUTH_CONTRACT_INFO_FAIL = 'GET_SIMPLE_AUTH_CONTRACT_INFO_FAIL',
  SET_VIP_CONTRACT_REQUEST = 'SET_VIP_CONTRACT_REQUEST',
  GET_PENDING_APPENDIX = 'GET_PENDING_APPENDIX',
  GET_PENDING_APPENDIX_ASYNC = 'GET_PENDING_APPENDIX_ASYNC',
  GET_PENDING_APPENDIX_SUCCESS = 'GET_PENDING_APPENDIX_SUCCESS',
  GET_PENDING_APPENDIX_FAIL = 'GET_PENDING_APPENDIX_FAIL',
  GET_PENDING_LABORER_APPENDIX = 'GET_PENDING_LABORER_APPENDIX',
  GET_PENDING_LABORER_APPENDIX_ASYNC = 'GET_PENDING_LABORER_APPENDIX_ASYNC',
  GET_PENDING_LABORER_APPENDIX_SUCCESS = 'GET_PENDING_LABORER_APPENDIX_SUCCESS',
  GET_PENDING_LABORER_APPENDIX_FAIL = 'GET_PENDING_LABORER_APPENDIX_FAIL',
  TOGGLE_CONTRAST = 'TOGGLE_CONTRAST',
  INCREASE_ZOOM = 'INCREASE_ZOOM',
  DECREASE_ZOOM = 'DECREASE_ZOOM',
  GET_TAMEENI_REDIRECTION_URL = 'GET_TAMEENI_REDIRECTION_URL',
  GET_TAMEENI_REDIRECTION_URL_ASYNC = 'GET_TAMEENI_REDIRECTION_URL_ASYNC',
  GET_TAMEENI_REDIRECTION_URL_SUCCESS = 'GET_TAMEENI_REDIRECTION_URL_SUCCESS',
  GET_TAMEENI_REDIRECTION_URL_FAIL = 'GET_TAMEENI_REDIRECTION_URL_FAIL',
  GET_DELAYED_CONTRACTS_COUNTS = 'GET_DELAYED_CONTRACTS_COUNTS',
  GET_DELAYED_CONTRACTS_COUNTS_ASYNC = 'GET_DELAYED_CONTRACTS_COUNTS_ASYNC',
  GET_DELAYED_CONTRACTS_COUNTS_SUCCESS = 'GET_DELAYED_CONTRACTS_COUNTS_SUCCESS',
  GET_DELAYED_CONTRACTS_COUNTS_FAIL = 'GET_DELAYED_CONTRACTS_COUNTS_FAIL',
  GET_VIP_VISA_LIST = 'GET_VIP_VISA_LIST',
  GET_VIP_VISA_LIST_ASYNC = 'GET_VIP_VISA_LIST_ASYNC',
  GET_VIP_VISA_LIST_SUCCESS = 'GET_VIP_VISA_LIST_SUCCESS',
  GET_VIP_VISA_LIST_FAIL = 'GET_VIP_VISA_LIST_FAIL',
  UPDATE_USER_CONTACTS = 'UPDATE_USER_CONTACTS',
  UPDATE_USER_CONTACTS_ASYNC = 'UPDATE_USER_CONTACTS_ASYNC',
  UPDATE_USER_CONTACTS_SUCCESS = 'UPDATE_USER_CONTACTS_SUCCESS',
  UPDATE_USER_CONTACTS_FAIL = 'UPDATE_USER_CONTACTS_FAIL',
  GET_VIP_PROS = 'GET_VIP_PROS',
  GET_VIP_PROS_ASYNC = 'GET_VIP_PROS_ASYNC',
  GET_VIP_PROS_SUCCESS = 'GET_VIP_PROS_SUCCESS',
  GET_VIP_PROS_FAIL = 'GET_VIP_PROS_FAIL',
  ADD_SELECTED_VIP_PRO = 'ADD_SELECTED_VIP_PRO',
  REMOVE_SELECTED_VIP_PRO = 'REMOVE_SELECTED_VIP_PRO',
  SELECT_VIP_PRO = 'SELECT_VIP_PRO',
  SELECT_VIP_PRO_ASYNC = 'SELECT_VIP_PRO_ASYNC',
  SELECT_VIP_PRO_SUCCESS = 'SELECT_VIP_PRO_SUCCESS',
  SELECT_VIP_PRO_FAIL = 'SELECT_VIP_PRO_FAIL',
  GET_MANAGER_INFO = 'GET_MANAGER_INFO',
  GET_MANAGER_INFO_ASYNC = 'GET_MANAGER_INFO_ASYNC',
  GET_MANAGER_INFO_SUCCESS = 'GET_MANAGER_INFO_SUCCESS',
  GET_MANAGER_INFO_FAIL = 'GET_MANAGER_INFO_FAIL',
  UPDATE_MANAGER_AUTH_STATUS = 'UPDATE_MANAGER_AUTH_STATUS',
  UPDATE_MANAGER_AUTH_STATUS_ASYNC = 'UPDATE_MANAGER_AUTH_STATUS_ASYNC',
  UPDATE_MANAGER_AUTH_STATUS_SUCCESS = 'UPDATE_MANAGER_AUTH_STATUS_SUCCESS',
  UPDATE_MANAGER_AUTH_STATUS_FAIL = 'UPDATE_MANAGER_AUTH_STATUS_FAIL',
  SET_LABORER_COMPLAINT_DETAILS = 'SET_LABORER_COMPLAINT_DETAILS',
  GET_LABORER_SUPPORT_TICKETS = 'GET_LABORER_SUPPORT_TICKETS',
  GET_LABORER_SUPPORT_TICKETS_ASYNC = 'GET_LABORER_SUPPORT_TICKETS_ASYNC',
  GET_LABORER_SUPPORT_TICKETS_SUCCESS = 'GET_LABORER_SUPPORT_TICKETS_SUCCESS',
  GET_LABORER_SUPPORT_TICKETS_FAIL = 'GET_LABORER_SUPPORT_TICKETS_FAIL',
  CREATE_LABORER_SUPPORT_TICKET = 'CREATE_LABORER_SUPPORT_TICKET',
  CREATE_LABORER_SUPPORT_TICKET_ASYNC = 'CREATE_LABORER_SUPPORT_TICKET_ASYNC',
  CREATE_LABORER_SUPPORT_TICKET_SUCCESS = 'CREATE_LABORER_SUPPORT_TICKET_SUCCESS',
  CREATE_LABORER_SUPPORT_TICKET_FAIL = 'CREATE_LABORER_SUPPORT_TICKET_FAIL',
  GET_LABORER_SUPPORT_ATTACHMENTS = 'GET_LABORER_SUPPORT_ATTACHMENTS',
  GET_LABORER_SUPPORT_ATTACHMENTS_ASYNC = 'GET_LABORER_SUPPORT_ATTACHMENTS_ASYNC',
  GET_LABORER_SUPPORT_ATTACHMENTS_SUCCESS = 'GET_LABORER_SUPPORT_ATTACHMENTS_SUCCESS',
  GET_LABORER_SUPPORT_ATTACHMENTS_FAIL = 'GET_LABORER_SUPPORT_ATTACHMENTS_FAIL',
  SEND_LABORER_REPORT = 'SEND_LABORER_REPORT',
  SEND_LABORER_REPORT_ASYNC = 'SEND_LABORER_REPORT_ASYNC',
  SEND_LABORER_REPORT_SUCCESS = 'SEND_LABORER_REPORT_SUCCESS',
  SEND_LABORER_REPORT_FAIL = 'SEND_LABORER_REPORT_FAIL',
  CANCEL_LABORER_REPORT = 'CANCEL_LABORER_REPORT',
  CANCEL_LABORER_REPORT_ASYNC = 'CANCEL_LABORER_REPORT_ASYNC',
  CANCEL_LABORER_REPORT_SUCCESS = 'CANCEL_LABORER_REPORT_SUCCESS',
  CANCEL_LABORER_REPORT_FAIL = 'CANCEL_LABORER_REPORT_FAIL',
  CHECK_LABORER_HAS_REPORT = 'CHECK_LABORER_HAS_REPORT',
  CHECK_LABORER_HAS_REPORT_ASYNC = 'CHECK_LABORER_HAS_REPORT_ASYNC',
  CHECK_LABORER_HAS_REPORT_SUCCESS = 'CHECK_LABORER_HAS_REPORT_SUCCESS',
  CHECK_LABORER_HAS_REPORT_FAIL = 'CHECK_LABORER_HAS_REPORT_FAIL',
  GET_LABORER_LISTING_REQUESTS = 'GET_LABORER_LISTING_REQUESTS',
  GET_LABORER_LISTING_REQUESTS_ASYNC = 'GET_LABORER_LISTING_REQUESTS_ASYNC',
  GET_LABORER_LISTING_REQUESTS_SUCCESS = 'GET_LABORER_LISTING_REQUESTS_SUCCESS',
  GET_LABORER_LISTING_REQUESTS_FAIL = 'GET_LABORER_LISTING_REQUESTS_FAIL',
  ACCEPT_LABORER_LISTING_REQUEST = 'ACCEPT_LABORER_LISTING_REQUEST',
  ACCEPT_LABORER_LISTING_REQUEST_ASYNC = 'ACCEPT_LABORER_LISTING_REQUEST_ASYNC',
  ACCEPT_LABORER_LISTING_REQUEST_SUCCESS = 'ACCEPT_LABORER_LISTING_REQUEST_SUCCESS',
  ACCEPT_LABORER_LISTING_REQUEST_FAIL = 'ACCEPT_LABORER_LISTING_REQUEST_FAIL',
  REJECT_LABORER_LISTING_REQUEST = 'REJECT_LABORER_LISTING_REQUEST',
  REJECT_LABORER_LISTING_REQUEST_ASYNC = 'REJECT_LABORER_LISTING_REQUEST_ASYNC',
  REJECT_LABORER_LISTING_REQUEST_SUCCESS = 'REJECT_LABORER_LISTING_REQUEST_SUCCESS',
  REJECT_LABORER_LISTING_REQUEST_FAIL = 'REJECT_LABORER_LISTING_REQUEST_FAIL',
  CHECK_LIST_LABORER_ELIGIBILITY = 'CHECK_LIST_LABORER_ELIGIBILITY',
  CHECK_LIST_LABORER_ELIGIBILITY_ASYNC = 'CHECK_LIST_LABORER_ELIGIBILITY_ASYNC',
  CHECK_LIST_LABORER_ELIGIBILITY_SUCCESS = 'CHECK_LIST_LABORER_ELIGIBILITY_SUCCESS',
  CHECK_LIST_LABORER_ELIGIBILITY_FAIL = 'CHECK_LIST_LABORER_ELIGIBILITY_FAIL',
  LIST_LABORER_CV = 'LIST_LABORER_CV',
  LIST_LABORER_CV_ASYNC = 'LIST_LABORER_CV_ASYNC',
  LIST_LABORER_CV_SUCCESS = 'LIST_LABORER_CV_SUCCESS',
  LIST_LABORER_CV_FAIL = 'LIST_LABORER_CV_FAIL',
  CANCEL_LISTING_LABORER_CV = 'CANCEL_LISTING_LABORER_CV',
  CANCEL_LISTING_LABORER_CV_ASYNC = 'CANCEL_LISTING_LABORER_CV_ASYNC',
  CANCEL_LISTING_LABORER_CV_SUCCESS = 'CANCEL_LISTING_LABORER_CV_SUCCESS',
  CANCEL_LISTING_LABORER_CV_FAIL = 'CANCEL_LISTING_LABORER_CV_FAIL',
  GET_LISTING_LABORER_CV_REQUEST = 'GET_LISTING_LABORER_CV_REQUEST',
  GET_LISTING_LABORER_CV_REQUEST_ASYNC = 'GET_LISTING_LABORER_CV_REQUEST_ASYNC',
  GET_LISTING_LABORER_CV_REQUEST_SUCCESS = 'GET_LISTING_LABORER_CV_REQUEST_SUCCESS',
  GET_LISTING_LABORER_CV_REQUEST_FAIL = 'GET_LISTING_LABORER_CV_REQUEST_FAIL',
  OPEN_LANGUAGE_MODAL = 'OPEN_LANGUAGE_MODAL',
  CLOSE_LANGUAGE_MODAL = 'CLOSE_LANGUAGE_MODAL',
  SET_LIST_LABORER_FILTER = 'SET_LIST_LABORER_FILTER',
  GET_LISTING_CVS_LIST = 'GET_LISTING_CVS_LIST',
  GET_LISTING_CVS_LIST_ASYNC = 'GET_LISTING_CVS_LIST_ASYNC',
  GET_LISTING_CVS_LIST_SUCCESS = 'GET_LISTING_CVS_LIST_SUCCESS',
  GET_LISTING_CVS_LIST_FAIL = 'GET_LISTING_CVS_LIST_FAIL',
  SELECT_LISTING_CV = 'SELECT_LISTING_CV',
  SELECT_LISTING_CV_ASYNC = 'SELECT_LISTING_CV_ASYNC',
  SELECT_LISTING_CV_SUCCESS = 'SELECT_LISTING_CV_SUCCESS',
  SELECT_LISTING_CV_FAIL = 'SELECT_LISTING_CV_FAIL',
  GET_LISTING_REQUEST_LIST = 'GET_LISTING_REQUEST_LIST',
  GET_LISTING_REQUEST_LIST_ASYNC = 'GET_LISTING_REQUEST_LIST_ASYNC',
  GET_LISTING_REQUEST_LIST_SUCCESS = 'GET_LISTING_REQUEST_LIST_SUCCESS',
  GET_LISTING_REQUEST_LIST_FAIL = 'GET_LISTING_REQUEST_LIST_FAIL',
  GET_LISTING_REQUEST = 'GET_LISTING_REQUEST',
  GET_LISTING_REQUEST_ASYNC = 'GET_LISTING_REQUEST_ASYNC',
  GET_LISTING_REQUEST_SUCCESS = 'GET_LISTING_REQUEST_SUCCESS',
  GET_LISTING_REQUEST_FAIL = 'GET_LISTING_REQUEST_FAIL',
  EXTEND_LISTING_REQUEST = 'EXTEND_LISTING_REQUEST',
  EXTEND_LISTING_REQUEST_ASYNC = 'EXTEND_LISTING_REQUEST_ASYNC',
  EXTEND_LISTING_REQUEST_SUCCESS = 'EXTEND_LISTING_REQUEST_SUCCESS',
  EXTEND_LISTING_REQUEST_FAIL = 'EXTEND_LISTING_REQUEST_FAIL',
  RESUBMIT_LISTING_REQUEST = 'RESUBMIT_LISTING_REQUEST',
  RESUBMIT_LISTING_REQUEST_ASYNC = 'RESUBMIT_LISTING_REQUEST_ASYNC',
  RESUBMIT_LISTING_REQUEST_SUCCESS = 'RESUBMIT_LISTING_REQUEST_SUCCESS',
  RESUBMIT_LISTING_REQUEST_FAIL = 'RESUBMIT_LISTING_REQUEST_FAIL',
  DISABLE_LISTING_REQUEST = 'DISABLE_LISTING_REQUEST',
  DISABLE_LISTING_REQUEST_ASYNC = 'DISABLE_LISTING_REQUEST_ASYNC',
  DISABLE_LISTING_REQUEST_SUCCESS = 'DISABLE_LISTING_REQUEST_SUCCESS',
  DISABLE_LISTING_REQUEST_FAIL = 'DISABLE_LISTING_REQUEST_FAIL',
  GET_LISTING_OFFER = 'GET_LISTING_OFFER',
  GET_LISTING_OFFER_ASYNC = 'GET_LISTING_OFFER_ASYNC',
  GET_LISTING_OFFER_SUCCESS = 'GET_LISTING_OFFER_SUCCESS',
  GET_LISTING_OFFER_FAIL = 'GET_LISTING_OFFER_FAIL',
  ACCEPT_LISTING_OFFER = 'ACCEPT_LISTING_OFFER',
  ACCEPT_LISTING_OFFER_ASYNC = 'ACCEPT_LISTING_OFFER_ASYNC',
  ACCEPT_LISTING_OFFER_SUCCESS = 'ACCEPT_LISTING_OFFER_SUCCESS',
  ACCEPT_LISTING_OFFER_FAIL = 'ACCEPT_LISTING_OFFER_FAIL',
  REJECT_LISTING_OFFER = 'REJECT_LISTING_OFFER',
  REJECT_LISTING_OFFER_ASYNC = 'REJECT_LISTING_OFFER_ASYNC',
  REJECT_LISTING_OFFER_SUCCESS = 'REJECT_LISTING_OFFER_SUCCESS',
  REJECT_LISTING_OFFER_FAIL = 'REJECT_LISTING_OFFER_FAIL',
}

export default Actions
