import { GetListingOfferResponse } from 'api/contractAuthAPI'
import { CallAPIAction, Status } from 'api/types'
import { ObjectWithTranslation } from 'common/types/transformedRespTypes'
import Actions from 'redux/actions'

type State = {
  data: {
    requestDetails: {
      expireAt: string
      monthlySalary: number
      requestDate: string
      requestStatus: string
      serviceCost: number
    }
    newEmployerDetails: {
      city: string
      mobile: string
      name: string
    }
    laborerDetails: {
      age: string
      qualification: string
      iqamaNumber: string
      location: string
      name: string
      nationality: string
      occupation: string
      skills: Array<ObjectWithTranslation>
      religion: string
      experience: number
      profilePicture: string
    }
  }
} & Status

const initialState: State = {
  data: {
    requestDetails: {
      expireAt: '',
      monthlySalary: 0,
      requestDate: '',
      requestStatus: '0',
      serviceCost: 0,
    },
    newEmployerDetails: {
      city: '',
      mobile: '',
      name: '',
    },
    laborerDetails: {
      age: '',
      qualification: '',
      iqamaNumber: '',
      location: '',
      name: '',
      nationality: '',
      occupation: '',
      skills: [],
      religion: '',
      experience: 0,
      profilePicture: '',
    },
  },
  isLoading: false,
  success: false,
  fail: false,
}

export default (
  state: State = initialState,
  action: CallAPIAction<
    Actions.GET_LISTING_OFFER_ASYNC,
    Actions.GET_LISTING_OFFER_SUCCESS,
    Actions.GET_LISTING_OFFER_FAIL,
    GetListingOfferResponse
  >,
): State => {
  switch (action.type) {
    case Actions.GET_LISTING_OFFER_ASYNC:
    case Actions.GET_LISTING_OFFER_FAIL: {
      return {
        ...state,
        ...action.status,
      }
    }

    case Actions.GET_LISTING_OFFER_SUCCESS: {
      const { request_details, new_employer_details, laborer_details } = action.payload.data

      return {
        ...state,
        ...action.status,
        data: {
          requestDetails: {
            expireAt: request_details.expire_at,
            monthlySalary: request_details.monthly_salary,
            requestDate: request_details.request_date,
            requestStatus: request_details.request_status,
            serviceCost: request_details.transfer_service_cost,
          },
          newEmployerDetails: {
            city: new_employer_details.city,
            mobile: new_employer_details.mobile,
            name: new_employer_details.name,
          },
          laborerDetails: {
            age: laborer_details.age,
            qualification: laborer_details.educational_qualification,
            iqamaNumber: laborer_details.iqama_number,
            location: laborer_details.location || '',
            name: laborer_details.name,
            nationality: laborer_details.nationality,
            occupation: laborer_details.occupation,
            skills: laborer_details.professional_skills.map((skill) => ({
              id: skill.id,
              label: skill.label_ar,
              labelEn: skill.label_en,
            })),
            religion: laborer_details.religion,
            experience: laborer_details.work_experience,
            profilePicture: laborer_details.laborer_profile || '',
          },
        },
      }
    }

    default:
      return state
  }
}
